import React, {useEffect, useState} from 'react';
import {Map} from "ol";

import 'ol/ol.css';
import {getBottomRight, getTopLeft} from "ol/extent";
import {transform} from "ol/proj";
import styles from './MapOverlay.module.scss';

type Props = {
    map: Map,
    children: any,
    coordinates: [number, number]|null,
    wrapperClassName?: string
}

const MapOverlay = ({ map, coordinates, children, ...props }: Props) => {
    const [currentCoordinatesDisplay, setCurrentCoordinatesDisplay] = useState([[0,0],[0,0]]);

    if (!coordinates) {
        coordinates = [0,0];
    }

    const updatePosition = () => {
        const extend = map.getView().calculateExtent(map.getSize());
        const topLeft = transform(getTopLeft(extend), 'EPSG:3857', 'EPSG:4326');
        const bottomRight = transform(getBottomRight(extend), 'EPSG:3857', 'EPSG:4326');

        setCurrentCoordinatesDisplay([[topLeft[0], topLeft[1]], [bottomRight[0], bottomRight[1]]]);
    }

    const duration = 10;
    useEffect(() => {
        const interval = setInterval(() => {
            updatePosition();
        }, duration);

        return () => clearInterval(interval);
    }, []);


    let top = (currentCoordinatesDisplay[0][1] - coordinates[1]) / (currentCoordinatesDisplay[0][1] - currentCoordinatesDisplay[1][1]);
    let left = (currentCoordinatesDisplay[0][0] - coordinates[0]) / (currentCoordinatesDisplay[0][0] - currentCoordinatesDisplay[1][0]);

    let display = 'block';
    if (top > 2 || top < -1 || left > 2 || left < -1) {
        display = 'none';
        top = -1;
        left = -1;
    }

    return (
        <div className={`${styles.wrapper} ${props.wrapperClassName ?? ''}`} style={{position: 'absolute', top: "48px", right: "16px", bottom: "16px", zIndex: 6, display: display}}>
            {children}
        </div>
    );
};

export default MapOverlay;
