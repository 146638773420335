import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import "ol/ol.css";
import {Feature, Map} from "ol";
import {transform, transformExtent} from "ol/proj";
import * as mapSettings from "../assets/map.json";
import polygonCenter from 'geojson-polygon-center';
import {containsExtent, getCenter} from "ol/extent";
import {Circle} from "ol/geom";
import {Coordinate} from "ol/coordinate";
import {
	checkMapWidthLimit,
	invisibleStyle,
	loadParkingZonesIntoMap,
	map,
	mapDebounce,
	myLocationMarker,
	myLocationMarkerStyleBackground,
	myLocationMarkerStyleCircle,
	parkingZones,
	styleElement,
	visibleZonesInViewport,
} from "../components/Map";
import MapSearch, {FilterInitialValues} from "../components/MapSearch/MapSearch";
import MapZoom from "../components/MapZoom/MapZoom";
import MapMyLocation from "../components/MapMyLocation/MapMyLocation";
import MapResults from "../components/MapResults/MapResults";
import MapOverlay from "../components/MapOverlay/MapOverlay";
import MapResultsSheet from "../components/MapResultsSheet/MapResultsSheet";
import DetailZoneItem from "../components/DetailZoneItem/DetailZoneItem";
import {fetchData} from "../services/api";
import {TFilter} from "../model/type/MapSearch/TFilter";
import {Mapbox} from "../components/Mapbox";
import Icon from "../components/Icon";
import styles from './Main.module.scss';
import DetailZoneActions from "../components/DetailZoneActions/DetalZoneActions";
import MapboxLogo from "../components/MapboxLogo/MapboxLogo";
import MapAttribution from "../components/MapAttribution/MapAttribution";
import {useTranslation} from "react-i18next";


const {mapZoom , mapAnimationDuration, minZoomZonesVisible, detailZoneZoom} = mapSettings;

export let selectedFeature:Feature;
export let hoveredFeature:Feature;

export function useMap() {
	const mapRef = useRef<Map>();
	if (!mapRef.current) {
		mapRef.current = map;
	}
	return mapRef.current;
}

const Main = () => {
	const {t} = useTranslation();

	const mapRef = useRef<HTMLDivElement>(null);
	const [isMapResultsForceClose, setIsMapResultForceClose] = useState(false);
	const [filter, setFilter] = useState<TFilter>(FilterInitialValues);
	const filterRef = useRef(filter);
	const [filteredZones, setFilteredZones] = useState([]);
	const [hideResults, setHideResults] = useState(true);
	const [detailZone, setDetailZone] = useState(null);
	const [detailZonePricing, setDetailZonePricing] = useState(null);
	const navigate = useNavigate();
	const [snapPoints, setSnapPoints] = useState([-145, 100]);
	const mapboxClient = new Mapbox(process.env.REACT_APP_MAP_API_KEY);
	const [mapMove, setMapMove] = useState(true);
	const [pricingDetail, setPricingDetail] = useState(false);
	const detailZoneRef = useRef(null);

	useEffect(() => {
		if(detailZone) {
			fetchData("Tickets/calculateprice", "POST", "body", {
				licensePlate: "string",
				sectionCode: detailZone.properties.sectionCode,
				tariff: detailZone.properties.tariffInfo.tariffID,
				requestedMinutes: 60
			}).then((data) => {
				setDetailZonePricing(Number(data.priceTotal));
			});
		}
	}, [detailZone]);

	const [isMapAutoMoving, setIsMapAutoMoving] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			if (detailZone && detailZoneRef.current && selectedFeature && selectedFeature instanceof Feature) {
				setIsMapAutoMoving(true);

				const geometry = selectedFeature.getGeometry();
				const featureCenter = geometry.getExtent();
				if (isMobile) {
					const bottomOffset = detailZoneRef.current.offsetHeight;
					map.getView().fit(featureCenter, {
						size: map.getSize(),
						padding: [10, 10, bottomOffset+25, 10],
						maxZoom: 18,
						duration: mapAnimationDuration
					});
				} else {
					const leftOffset = 364;
					const rightOffset = 364;
					map.getView().fit(featureCenter, {
						size: map.getSize(),
						padding: [50, rightOffset+35, 50, leftOffset+35],
						maxZoom: 18,
						duration: mapAnimationDuration
					});
				}


				const onMove = function() {
					let currentZoom = map.getView().getZoom();
					if (isMapAutoMoving) {
						if (currentZoom <= minZoomZonesVisible) {
							map.getView().setZoom(minZoomZonesVisible);
						}
					}
				};

				map.getView().on('change:resolution', onMove);

				setTimeout(() => {
					setIsMapAutoMoving(false);
					if (isMapAutoMoving) {
						map.getView().on('change:resolution', onMove);
					}
				}, mapAnimationDuration);
			}
		}, 100);
	}, [detailZone, detailZoneRef, pricingDetail, detailZoneRef.current?.offsetHeight]);

	useEffect(() => {
		filterRef.current = filter;
	}, [filter]);

	useEffect(() => {
		setFilter(filter);
		filterZonesOnMap();
	}, [filter]);

	const filterZonesOnMap = () => {
		//todo: kdyz je vybrana zona tak ji pripadne zavrit pokud se tyka ovlivnene zony

		parkingZones.getSource().getFeatures().forEach((feature) => {
			let properties = feature.getProperties();
			if (filterRef.current.zones[properties.sectionCategoryID] === true) {
				if (selectedFeature?.getProperties().sectionID !== feature.getProperties().sectionID) {
					feature.setStyle(styleElement(properties.sectionCategoryColor, false));
					feature.setProperties({...properties, visible: true})
				}
			} else {
				feature.setStyle(invisibleStyle);
				feature.setProperties({...properties, visible: false})
			}

			if(filterRef.current.parking.to) {
				if ((filterRef.current.parking.to / 60) <= feature.getProperties().tariffInfo.maxHour) {
					feature.setStyle(styleElement(properties.sectionCategoryColor, false));
					feature.setProperties({...properties, visible: true})
				} else {
					feature.setStyle(invisibleStyle);
					feature.setProperties({...properties, visible: false})
				}
			}
		});

		setFilteredZones(visibleZonesInViewport());
	};
	const getCenterOfPolygons = (polygon) => {
		return polygonCenter(polygon).coordinates;
	}

	const isMobile = window.innerWidth < 575.98;
	const isDesktop = window.innerWidth >= 575.98;

	const loadDetailZone = (section: { [p: string]: any }, hasAnimation: boolean = false) => {
		setMapMove(false);
		redirectToSection(section.sectionCode);

		if (selectedFeature) {
			selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
			selectedFeature = null;
		}

		let feature = parkingZones.getSource().getFeatureById(section.sectionID);

		if(feature instanceof Feature){
			selectedFeature = feature;
			feature.setStyle(styleElement(section.sectionCategoryColor, true));
		}

		if (!hasAnimation) {
			if(feature === null){
				setTimeout(() => {
					feature = parkingZones.getSource().getFeatureById(section.sectionID);
					if(feature instanceof Feature){
						selectedFeature = feature;
						feature.setStyle(styleElement(section.sectionCategoryColor, true));
					}
				}, 500);
			}
		}

		let cordsDetail: Coordinate;

		if(feature instanceof Feature) {
			let geometry = feature.getGeometry();
			cordsDetail = transform(getCenter(geometry.getExtent()), 'EPSG:3857', 'EPSG:4326');
		}

		setDetailZone({
			coordinates: cordsDetail,
			properties: section
		})

		setIsMapResultForceClose(true);
	}

	useEffect(() => {
		if (filterRef.current.query.value.startsWith('mapbox_')) {
			// @ts-ignore
			mapboxClient.getSuggestedFeature({mapbox_id: filterRef.current.query.value.replaceAll("mapbox_", "")})
				.then((feature) => {
					const coords = transform(feature.features[0].geometry.coordinates, 'EPSG:4326', 'EPSG:3857');
					map.getView().animate({
						center: coords,
						zoom: detailZoneZoom,
						duration: mapAnimationDuration
					});
				})
		} else if (filterRef.current.query.value.startsWith('api_')) {
			fetchData("Parking/sections/2", "GET", "url",
				{
					sectionCode: filterRef.current.query.value.replaceAll("api_", "")
				}).then((results) => {
				loadParkingZonesIntoMap(results).then(r => {
					let feature = parkingZones.getSource().getFeatureById(results[0].sectionID);
					setTimeout(() => {
						if(feature instanceof Feature){

							loadDetailZone(feature.getProperties(), false);
							selectedFeature = feature;
							redirectToSection(feature.getProperties().sectionCode);
							let geometry = feature.getGeometry();
							let coordinates: Coordinate = transform(getCenter(geometry.getExtent()), 'EPSG:3857', 'EPSG:4326');
							setDetailZone({
								coordinates: coordinates,
								properties: feature.getProperties()
							})
						}
					}, 500)


				});
			});
		}
	}, [filterRef.current.query])

	const isZoneVisibleOnMap = (sectionCode: string) => filteredZones.filter((zone) => zone.sectionCode === sectionCode).length > 0

	const getAutoLocation = useCallback(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					const view = map.getView();
					const currentCenter = view.getCenter();
					const [mappedLongitude, mappedLatitude] = transform(
						[longitude, latitude],
						'EPSG:4326',
						'EPSG:3857'
					);
					const currentZoom = view.getZoom();

					view.animate(
						{
							center: currentCenter,
							zoom: currentZoom,
						},
						{
							center: [mappedLongitude, mappedLatitude],
							zoom: mapZoom,
							duration: mapAnimationDuration,
						}
					);

					const featureCircle = new Feature(new Circle([mappedLongitude, mappedLatitude], 4));
					featureCircle.setStyle(myLocationMarkerStyleCircle);

					const featureBg = new Feature(new Circle([mappedLongitude, mappedLatitude], 8));
					featureBg.setStyle(myLocationMarkerStyleBackground);

					myLocationMarker.getSource().addFeatures([featureBg, featureCircle])
				},
				(error) => {
					//console.error("Error getting location:", error);
				}
			);
		} else {
			console.error("Geolocation is not supported by this browser.");
		}
	}, [map]);

	const redirectToSection = (sectionCode: string) => {
		navigate(`/q/${sectionCode}`);
	};

	const loadMapDataByPosition = async  () => {
		if (map.getView().getZoom() >= minZoomZonesVisible) {
			parkingZones.setVisible(true);
			setHideResults(false);
		} else {
			parkingZones.setVisible(false);
			setHideResults(true);
			setIsMapResultForceClose(false);
			setDetailZone(null);
			if (selectedFeature) {
				selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
				selectedFeature = null;
			}
		}

		if (checkMapWidthLimit() === true && map.getView().getZoom() >= minZoomZonesVisible) {
			const boundaries: Array<number> = transformExtent(map.getView().calculateExtent(map.getSize()), 'EPSG:3857', 'EPSG:4326');
			// TODO: rychlej fix, pak vratit na sections=1
			if(boundaries[0] && boundaries[1] && boundaries[2] && boundaries[3]) {
				const data = await fetchData("Parking/sections/2/byBoundaries", "GET", "url", {
					minLatitude: boundaries[1],
					minLongitude: boundaries[0],
					maxLatitude: boundaries[3],
					maxLongitude: boundaries[2]
				});

				loadParkingZonesIntoMap(data).then(() => {
					filterZonesOnMap();
				});
				//setFilteredZones(visibleZonesInViewport());

				setDetailZone((state) => {
					if (state !== null && data.filter(section => section.sectionCode === state.properties.sectionCode).length === 0) {
						//if()
						navigate(`/`);
						return null;
					}

					return state;
				});

				if (detailZone !== null && data.filter(section => section.sectionCode === detailZone.properties.sectionCode).length === 0) {
					setDetailZone(null);
				}
			}
		}
	}

	useEffect(() => {
		loadMapDataByPosition();
	}, []);

	useEffect(() => {
		if (mapRef.current) {
			map.setTarget(mapRef.current);
			map.updateSize();
		}

		map.on("movestart", (evt) => {
			setMapMove(true);
		})

		map.on("moveend", mapDebounce(async (evt) => {
			loadMapDataByPosition();
		}, 15));

		map.on("moveend", (evt) => {
			if(selectedFeature){
				let viewExtent = map.getView().calculateExtent(map.getSize());
				let featureExtent = selectedFeature.getGeometry().getExtent();
				let isVisible = containsExtent(viewExtent, featureExtent);

				if(!isVisible && !map.getView().getAnimating() && mapMove === false){
					navigate(`/`);
					setIsMapResultForceClose(false);
					setDetailZone(null);
					if (selectedFeature) {
						selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
						selectedFeature = null;
					}
				}
			}
		});

		map.on('pointermove', (evt) => {
			const pixel = map.getEventPixel(evt.originalEvent);
			const hit = map.hasFeatureAtPixel(pixel);

			if (hit) {
				const feature = map.forEachFeatureAtPixel(pixel, (feature) => feature);

				if (feature !== selectedFeature && feature instanceof Feature && feature.getProperties().sectionID) {
					if (hoveredFeature && hoveredFeature !== selectedFeature) {
						hoveredFeature.setStyle(styleElement(hoveredFeature.getProperties().sectionCategoryColor, false, false));
					}

					feature.setStyle(styleElement(feature.getProperties().sectionCategoryColor, false, true));
					hoveredFeature = feature;
					map.getViewport().style.cursor = 'pointer';
				} else if (hoveredFeature && hoveredFeature !== selectedFeature) {
					hoveredFeature.setStyle(styleElement(hoveredFeature.getProperties().sectionCategoryColor, false, false));
					hoveredFeature = null;
					map.getViewport().style.cursor = '';
				}
			} else if (hoveredFeature && hoveredFeature !== selectedFeature) {
				hoveredFeature.setStyle(styleElement(hoveredFeature.getProperties().sectionCategoryColor, false, false));
				hoveredFeature = null;
				map.getViewport().style.cursor = '';
			}
		});

		map.on('singleclick', (evt) => {
			const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => feature);

			if (selectedFeature) {
				selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false, false));
			}

			if (feature instanceof Feature && feature.getProperties().sectionCode) {
				const properties = feature.getProperties();

				if (properties.sectionCode !== selectedFeature?.getProperties().sectionCode) {
					setIsMapResultForceClose(true);
					loadDetailZone(properties, true);
					redirectToSection(properties.sectionCode);
				}

				selectedFeature = feature;

				map.getViewport().style.cursor = '';
			} else {
				navigate(`/`);
				setIsMapResultForceClose(false);
				selectedFeature = null;
			}

			if (feature?.getProperties()?.sectionID) {
				const geometry = feature.getGeometry();
				const coordinates = transform(getCenter(geometry.getExtent()), 'EPSG:3857', 'EPSG:4326');
				setDetailZone({
					coordinates: coordinates,
					properties: feature.getProperties()
				});
			} else {
				setDetailZone(null);
			}

			if (feature instanceof Feature && feature?.getProperties()?.sectionID) {
				selectedFeature = feature;
				selectedFeature.setStyle(styleElement(feature.getProperties().sectionCategoryColor, true, null));
			}
		});

		getAutoLocation();
	}, [map, getAutoLocation]);

	const location = useLocation();

	useEffect(() => {
		if (selectedFeature && !location.pathname.startsWith("/q/")) {
			selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
			setDetailZone(null);
			selectedFeature = null;
		}
	}, [location]);

	const [filterIsOpened, setFilterIsOpened] = useState(false);

	return (
		<div className={styles.container}>
			<MapOverlay map={map} coordinates={detailZone?.coordinates}>
				{detailZone && (
					<div className={styles.parkingZoneDetail} style={{"border": "1px solid " + selectedFeature?.getProperties().sectionCategoryColor}} id="parkingZoneDetail" ref={detailZoneRef}>
						<div className={styles.closeOverlay} onClick={() => {
							navigate(`/`);
							setIsMapResultForceClose(false);
							selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
							selectedFeature = null;
							setDetailZone(null);
						}}>
							<Icon.RemoveTiny size={(isMobile ? 18 : 12)} color={'white'} />
						</div>
						<DetailZoneItem
							type={"name"}
							value={detailZone.properties.sectionCode}
							subValue={t("home.MapSearchComponent.zones." + detailZone.properties.sectionCategoryName)}
							//subValue={detailZone.properties.sectionCategoryName}
						/>
						{detailZone.properties.streetName !== null && (
							<DetailZoneItem type={"street"} value={detailZone.properties.streetName}/>
						)}
						<DetailZoneItem type={"time"} value={detailZone.properties.tariffInfo?.maxHour}/>
						<DetailZoneItem
							type="pricing"
							value={detailZonePricing}
							subValue={detailZone.properties.tariffInfo}
							id={detailZone.properties.sectionCode}
							onTogglePricingDetail={(state) => setPricingDetail(state)}
							pricingDetailState={pricingDetail}
						/>
						<DetailZoneActions canBuy={detailZonePricing !== 0} sectionCode={detailZone.properties.sectionCode}/>
					</div>
				)}
			</MapOverlay>

			<div className={styles.map}>
				<div className={styles.mapContainer}>
					<div id="map" className={styles.map} ref={mapRef}></div>
				</div>
			</div>

			{(isDesktop || (isMobile && detailZone === null)) && (
				<div className={styles.sidePanel}>
					<MapSearch
						filter={filter}
						isZoneVisibleOnMap={isZoneVisibleOnMap}
						onChangeFilter={(values) => {
							setFilter(values);
							filterZonesOnMap();
						}}
						wrapperClassName={styles.mapSearch}
						onOpenFilter={() => {
							setSnapPoints([-500, 100]);
							setFilterIsOpened(true)
						}}
						onCloseFilter={() => {
							setSnapPoints([-195, 100]);
							setFilterIsOpened(false)
						}}
					/>
					<MapMyLocation showOn={"mobile"}/>
					{
						isMobile ?
							<><MapboxLogo /><MapAttribution /></>
							: null
					}

					<MapResults
						wrapperClassName={`${styles.mapResults} ${styles.showOnDesktop} ${filterIsOpened ? styles.filterIsOpened : ''}`}
						filteredResults={filteredZones}
						hideResults={hideResults}
						currentSectionCode={detailZone?.properties.sectionCode ?? null}
						onSelect={(section) => {
							loadDetailZone(section, true);
						}}
					/>
				</div>
			)}

			<div id="mapControls">
				{ (!detailZone && isDesktop) || isMobile ? (
					<div className={styles.zoomPanel}>
						{isDesktop ? <MapboxLogo /> : null}
						<div className={styles.zoomButtonContainer}>
							<MapZoom action={"in"} step={1} />
							<MapZoom action={"out"} step={1} />
						</div>
						{isDesktop ? (
							<div>
								<MapAttribution />
							</div>
						) : null}
					</div>
				) : null}

				{ !detailZone ? (
				<div className={styles.locationPanel}>
					<MapMyLocation showOn={"desktop"}/>
				</div>
				) : null }
				{ !detailZone ?
				<MapResultsSheet
					forceClose={isMapResultsForceClose}
					filteredZones={filteredZones}
					hideResults={hideResults}
					snapPoint={snapPoints}
					onSelect={(section) => {
						loadDetailZone(section, true);
					}}
				/>
					: null }
			</div>
		</div>
	);
}

export default Main;
