import styles from './MapResultItem.module.scss';

type Props = {
	wrapperClassName?: string
	placeName: string,
	parkingInfo: string
	sectionId: string,
	isCurrent?: boolean,
	onClick?: () => void
};

const MapResultItem: React.FCC<Props> = ({ isCurrent = false, ...props}) => {
	return (
		<div className={`${styles.item} ${props.wrapperClassName} ${isCurrent ? styles.current : ''}`} onClick={() => props.onClick && props.onClick()}>
			<div className={styles.info}>
				<div className={styles.title}>
					<div className={styles.placeName}>
						{props.placeName}
					</div>
					<div className={styles.parkingInfo}>
						{props.parkingInfo}
					</div>
				</div>
				<div className={styles.openIcon}>
					<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M2.12695 0.71875L8.20312 7.06055C8.33594 7.22656 8.43555 7.42578 8.43555 7.625C8.43555 7.82422 8.33594 8.02344 8.20312 8.15625L2.12695 14.498C1.82812 14.8301 1.29688 14.8301 0.998047 14.5312C0.666016 14.2324 0.666016 13.7344 0.964844 13.4023L6.54297 7.5918L0.964844 1.81445C0.666016 1.51562 0.666016 0.984375 0.998047 0.685547C1.29688 0.386719 1.82812 0.386719 2.12695 0.71875Z"
							fill="#3C3C43" fillOpacity="0.3"/>
					</svg>
				</div>
			</div>
		</div>
	);
}

export default MapResultItem;