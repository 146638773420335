import React from "react";
import savedStyles from '../Settings.module.scss';
import {Link, useNavigate, useParams} from "react-router-dom";
import Icon from "../../components/Icon";
import RemoveDialog from "./RemoveDialog";
import useVehiclesState from "../../model/useVehiclesState";
import {t} from "i18next";

const RemoveVehicle = () => {
	const {deleteByIndex, getByIndex} = useVehiclesState();
	const {vehicleId} = useParams();
	const vehicle = getByIndex('spz', vehicleId);
	const navigateTo = useNavigate();

	return (
		<div className={savedStyles._container}>
			<div className={savedStyles.wrapper}>
				<Link to={'/settings'} className={`d-flex-center gap-3 ${savedStyles.backLink}`}>
					<Icon.LeftChevron size={12} />
					{t("settings.removeVehicle.back")}
				</Link>
				<h1>{t("settings.removeVehicle.title")}</h1>
				{vehicle && (
					<RemoveDialog
						type={'vehicle'}
						title={`${vehicle.name} - ${vehicle.spz}`}
						onCancel={() => {
							navigateTo('/settings');
						}}
						onSubmit={() => {
							deleteByIndex('spz', vehicleId);
							navigateTo('/settings');
						}}
					/>
				)}
			</div>
		</div>
	);
}

export default RemoveVehicle;