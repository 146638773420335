import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import * as mapSettings from "../assets/map.json";
import MapZoom from "../components/MapZoom/MapZoom";
import MapMyLocation from "../components/MapMyLocation/MapMyLocation";
import styles from './Main.module.scss';

const {mapZoom , mapAnimationDuration, minZoomZonesVisible, detailZoneZoom, mapCenter} = mapSettings;


import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import {fetchData} from "../services/api";
import {checkMapWidthLimit, loadParkingZonesIntoMap, mapDebounce} from "../components/MapboxMap";

mapboxgl.accessToken = process.env.REACT_APP_MAP_API_KEY;

const Main = () => {

	// New map
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [lng, setLng] = useState(mapCenter[0]);
	const [lat, setLat] = useState(mapCenter[1]);
	const [zoom, setZoom] = useState(mapZoom);

	useEffect(() => {
		if (map.current) return;
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			center: [lng, lat],
			zoom: zoom,
			minZoom: minZoomZonesVisible,
			dragRotate: false,
			pitchWithRotate: false,
			attributionControl: false,
			touchPitch: false
		});

		map.current.on('move', () => {
			setLng(map.current.getCenter().lng.toFixed(4));
			setLat(map.current.getCenter().lat.toFixed(4));
			setZoom(map.current.getZoom().toFixed(2));
		});

		map.current.on("moveend", mapDebounce(async () => {
			const newData = await loadMapDataByPosition();
			map.current.getSource("parkingZonesSource").setData(newData);
		}, 150));
	});

	useEffect(() => {
		loadMapDataByPosition();
	}, []);

	const loadMapDataByPosition = async  () => {
		if (checkMapWidthLimit(map.current) === true && zoom >= minZoomZonesVisible) {
			const boundaries = map.current.getBounds();

			if (boundaries.getWest() && boundaries.getSouth() && boundaries.getEast() && boundaries.getNorth()) {
				const data = await fetchData("Parking/sections/2/byBoundaries", "GET", "url", {
					minLatitude: boundaries.getSouth(),
					minLongitude: boundaries.getWest(),
					maxLatitude: boundaries.getNorth(),
					maxLongitude: boundaries.getEast()
				});

				await loadParkingZonesIntoMap(data, map.current);
				
			}
		}
	}


	return (
		<div className={styles.container}>
			<div className={styles.map}>
				<div className={styles.mapContainer}>
					<div ref={mapContainer} className={styles.map}/>
				</div>
			</div>

			<div id="mapControls">

			</div>
		</div>
	);
}

export default Main;
