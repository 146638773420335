import { useState, useEffect } from 'react';

export default function useIntervalState(updateFunction, initialState = null, intervalDuration = 100) {
	const [state, setState] = useState(initialState ?? null);

	useEffect(() => {
		const interval = setInterval(() => {
			updateFunction(setState);
		}, intervalDuration);

		return () => clearInterval(interval);
	}, [intervalDuration, updateFunction]);

	return [state, setState];
}