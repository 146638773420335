import dayjs from "dayjs";

export const calculateParkingTimes = (orderDate: Date, endDate: Date) => {
    if (!orderDate || !endDate) {
        return {remaining: null, total: null}
    }

    const now = new Date().getTime();
    const start = orderDate.getTime();
    const end = endDate.getTime();

    return {remaining: (end - now), total: (end - start)};
}

export const addMinutesAndFormat = (minutes:number, date?: Date, format: string = 'D. M. HH:mm') => {
    return dayjs(date).add(minutes, 'minutes').format(format);
}