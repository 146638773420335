import React, {useEffect, useState} from "react";
import styles from './Settings.module.scss';
import Icon from "../components/Icon";
import {Link} from "react-router-dom";

import useVehiclesState from "../model/useVehiclesState";
import useCardsState from "../model/useCardsState";
import {useTranslation} from "react-i18next";
import useEmailsState from "../model/useEmailsState";

const version = process.env.REACT_APP_VERSION;

const Settings = () => {
    const { vehicles, clearAll: clearAllVehicles } = useVehiclesState();
    const { cards, clearAll: clearAllCards } = useCardsState();
    const { emails, clearAll: clearAllEmails } = useEmailsState();

    const {t} = useTranslation();

    const [deployment, setDeployment] = useState(null);

    useEffect(() => {
        fetch('/deploy.txt')
            .then(response => response.text())
            .then(text => setDeployment(text.trim()))
    }, []);

    return (
        <div className={styles._container}>
            <div className={styles.wrapper}>
                <h1>{t("settings.title")}</h1>
                <div className={styles.itemGroups}>
                    <div className={styles.itemGroup}>
                        <h2 className={styles.header}>
                            {t("settings.paymentMethods")}
                        </h2>
                        {cards.map((card, key) => (
                            <div className={styles.item} key={key}>
                                <div className={styles.labelIcon}>
                                    <Icon.PaymentCard size={22}/>
                                </div>
                                <div className={styles.cardContent}>
                                    <div>{card.name}</div>
                                    <div className={'text-muted'}>•••• {card.cardMaskedCLN.slice(-4)}</div>
                                </div>
                                <div className={styles.icon}>
                                    <Link className={`btn btn-outline-danger ${styles.removeButton}`}
                                          to={`/settings/card/remove/${card.id}`}>
                                        <Icon.Trash size={17}/>
                                    </Link>
                                </div>
                            </div>
                        ))}
                        {cards.length === 0 && (
                            <div className={`text-muted ${styles.item}`}>{t("settings.noPaymentMethods")}</div>
                        )}
                    </div>
                    <div className={styles.itemGroup}>
                        <h2 className={styles.header}>
                            {t("settings.cars")}
                            <Link className={`btn btn-primary ${styles.buttonAddVehicle}`} to={'/settings/vehicle/new'}>
                                <Icon.Plus size={14}/>
                                {t("settings.addNewCar")}
                            </Link>
                        </h2>
                        {vehicles.map((vehicle, key) => (
                            <div className={styles.item} key={key}>
                                <div className={styles.labelIcon}>
                                    <Icon.Car size={22}/>
                                </div>
                                <div className={styles.cardContentCar}>
                                    <div>{vehicle.name}</div>
                                    <div className={'text-muted'}>{vehicle.spz}</div>
                                </div>
                                {/*<div className={styles.show}>*/}
                                <div className={styles.icon}>
                                    <Link className={`btn btn-outline-danger ${styles.removeButton}`}
                                          to={`/settings/vehicle/remove/${vehicle.spz}`}>
                                        <Icon.Trash size={17}/>
                                    </Link>

                                    {/*<Link to={`/settings/vehicle/remove/${vehicle.spz}`} className={`btn ${styles.showButton}`}>
                                        {t("settings.show")}
                                        <Icon.RightChevron size={14}/>
                                    </Link>*/}
                                </div>
                            </div>
                        ))}
                        {vehicles.length === 0 && (
                            <div className={`text-muted ${styles.item}`}>{t("settings.noCars")}</div>
                        )}
                    </div>
                    <div className={styles.itemGroup}>
                        <h2 className={styles.header}>
                            {t("settings.email")}
                            {emails.length === 0 ?
                            <Link className={`btn btn-primary ${styles.buttonAddVehicle}`} to={'/settings/email/new'}>
                                <Icon.Plus size={14}/>
                                {t("settings.addNewEmail")}
                            </Link> : ""
                            }
                        </h2>
                        {emails.map((email, key) => (
                            <div className={styles.item} key={key}>
                                <div className={styles.labelIcon}>
                                    <Icon.Email size={22}/>
                                </div>
                                <div className={styles.cardContentCar}>
                                    <div>{email.email}</div>
                                </div>
                                {/*<div className={styles.show}>*/}
                                <div className={styles.icon}>
                                    <Link className={`btn btn-outline-danger ${styles.removeButton}`}
                                          to={`/settings/email/remove/${email.email}`}>
                                        <Icon.Trash size={17}/>
                                    </Link>
                                </div>
                            </div>
                        ))}
                        {emails.length === 0 && (
                            <div className={`text-muted ${styles.item}`}>{t("settings.noEmails")}</div>
                        )}
                    </div>
                    {/*  <div className={styles.itemGroup}>
                        <h2>Podmínky aplikace</h2>
                        <div className={styles.item}>
                            <div className={styles.labelIcon}>
                            <Icon.SafeBadge size={22}/>
                            </div>
                            <div className={styles.cardContent}>
                                <div>Podmínky</div>
                            </div>
                            <div className={styles.show}>
                                <button className={`btn ${styles.showButton}`}>
                                    Zobrazit
                                    <Icon.RightChevron size={14}/>
                                </button>
                            </div>
                        </div>
                    </div>*/}
                    <div className={styles.itemGroup}>
                        <h2>{t("settings.removeData.title")}</h2>
                        <div className={styles.item}>
                            <div className={styles.labelIcon}>
                                <Icon.Trash size={22}/>
                            </div>
                            <div className={styles.cardContentRemoveAll}>
                                <div>{t("settings.removeData.subtitle")}</div>
                                <div className={'text-muted'}>{t("settings.removeData.itemsToRemove")}</div>
                            </div>
                            <div className={styles.removeAll}>
                                <button className={`btn ${styles.removeAllButton}`} onClick={() => {
                                    clearAllVehicles();
                                    clearAllCards();
                                    clearAllEmails();
                                }}>
                                    {t("settings.removeData.removeAll")}
                                    <Icon.RightChevron size={14}/>
                                </button>
                            </div>
                        </div>
                        <div className={`${styles.itemGroup} mt-5`}>
                            <p className={`${styles.version} mt-0 mb-0`}>ver: {version} ({deployment})</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;