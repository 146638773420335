import {fetchData} from "../../services/api";

export const getTicket = (ticketGUID: string, callback?: any) => {
    fetchData(`Tickets/${ticketGUID}`, 'GET')
        .then((data) => {
            if (callback) {
                callback(data);
            }
        })
}

export const downloadReceipt = (ticketGUID: string) => {
    fetchData(`Receipts/${ticketGUID}/pdf`, 'GET')
        .then((data) => {
            const url = URL.createObjectURL(data);
            const downloadLink = document.createElement('a');

            downloadLink.href = url;
            downloadLink.download = 'parking-praha-doklad.pdf';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(url);
        });
}