import styles from './DetailZoneActions.module.scss';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";

type Props = {
    sectionCode: string,
    canBuy: boolean
}

const DetailZoneActions = ({sectionCode, canBuy}: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.actions}>
            {/*<a className={styles.addToFavorite}>
                <svg width="16" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.625 1.625L7.96875 2L8.34375 1.65625C9.375 0.625 10.8438 0.15625 12.25 0.40625C14.4062 0.75 16 2.625 16 4.8125V4.96875C16 6.28125 15.4375 7.53125 14.5 8.40625L8.84375 13.6875C8.625 13.9062 8.3125 14 8 14C7.65625 14 7.34375 13.9062 7.125 13.6875L1.46875 8.40625C0.53125 7.53125 0 6.28125 0 4.96875V4.8125C0 2.625 1.5625 0.75 3.71875 0.40625C5.125 0.15625 6.59375 0.625 7.625 1.625C7.625 1.65625 7.59375 1.625 7.625 1.625ZM7.96875 4.125L6.5625 2.6875C5.875 2.03125 4.90625 1.71875 3.96875 1.875C2.53125 2.125 1.5 3.34375 1.5 4.8125V4.96875C1.5 5.875 1.84375 6.71875 2.5 7.3125L8 12.4375L13.4688 7.3125C14.125 6.71875 14.5 5.875 14.5 4.96875V4.8125C14.5 3.34375 13.4375 2.125 12 1.875C11.0625 1.71875 10.0938 2.03125 9.40625 2.6875L7.96875 4.125Z"
                        fill="#1D4F8B"/>
                    <path
                        d="M7.625 1.625L7.96875 2L8.34375 1.65625C9.375 0.625 10.8438 0.15625 12.25 0.40625C14.4062 0.75 16 2.625 16 4.8125V4.96875C16 6.28125 15.4375 7.53125 14.5 8.40625L8.84375 13.6875C8.625 13.9062 8.3125 14 8 14C7.65625 14 7.34375 13.9062 7.125 13.6875L1.46875 8.40625C0.53125 7.53125 0 6.28125 0 4.96875V4.8125C0 2.625 1.5625 0.75 3.71875 0.40625C5.125 0.15625 6.59375 0.625 7.625 1.625C7.625 1.65625 7.59375 1.625 7.625 1.625ZM7.96875 4.125L6.5625 2.6875C5.875 2.03125 4.90625 1.71875 3.96875 1.875C2.53125 2.125 1.5 3.34375 1.5 4.8125V4.96875C1.5 5.875 1.84375 6.71875 2.5 7.3125L8 12.4375L13.4688 7.3125C14.125 6.71875 14.5 5.875 14.5 4.96875V4.8125C14.5 3.34375 13.4375 2.125 12 1.875C11.0625 1.71875 10.0938 2.03125 9.40625 2.6875L7.96875 4.125Z"
                        fill="black" fill-opacity="0.2"/>
                </svg>

            </a>*/}

            <Link to={`/buy/${sectionCode}`} className={`${styles.buyTicket} ${canBuy ? `` : styles.disabledLink}`}>
                <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3 0.5C3.25781 0.5 3.49219 0.710938 3.53906 0.96875L3.58594 1.25H13.4297C13.9219 1.25 14.2969 1.74219 14.1562 2.21094L12.8906 6.71094C12.7969 7.03906 12.5156 7.25 12.1641 7.25H4.73438L4.94531 8.375H12.1875C12.4922 8.375 12.75 8.63281 12.75 8.9375C12.75 9.26562 12.4922 9.5 12.1875 9.5H4.47656C4.21875 9.5 3.98438 9.3125 3.9375 9.05469L2.53125 1.625H1.3125C0.984375 1.625 0.75 1.39062 0.75 1.0625C0.75 0.757812 0.984375 0.5 1.3125 0.5H3ZM3.75 11.375C3.75 10.7656 4.24219 10.25 4.875 10.25C5.48438 10.25 6 10.7656 6 11.375C6 12.0078 5.48438 12.5 4.875 12.5C4.24219 12.5 3.75 12.0078 3.75 11.375ZM12.75 11.375C12.75 12.0078 12.2344 12.5 11.625 12.5C10.9922 12.5 10.5 12.0078 10.5 11.375C10.5 10.7656 10.9922 10.25 11.625 10.25C12.2344 10.25 12.75 10.7656 12.75 11.375Z"
                        fill="#FFDF21"/>
                </svg>
                &nbsp;{t("home.DetailZoneActionsComponent.buy")}
            </Link>
        </div>
    );
};

export default DetailZoneActions;