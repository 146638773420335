import React, { useState, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';

type Props = {
	debounceMs: number,
	onSubmitStarted?: () => void
	onSubmitCompleted?: () => void
}

const AutoSubmit = ({ debounceMs = 1000, ...props}: Props) => {
	const formik = useFormikContext();
	const [isSaved, setIsSaved] = useState(null);
	const debouncedSubmit = useCallback(
		_.debounce(() => {
			props.onSubmitStarted && props.onSubmitStarted();
			return formik.submitForm()
				.then(() => {
					setIsSaved(true);
					props.onSubmitCompleted && props.onSubmitCompleted();
				})
				.catch((e) => console.log(e));
		}, debounceMs),
		[formik.submitForm, debounceMs],
	);

	useEffect(() => debouncedSubmit, [debouncedSubmit, formik.values]);

	return (
		<></>
		/*<p className="text-center text-success">
			{!!formik.isSubmitting
				? 'Saving...'
				: isSaved
					? 'Your changes saved.'
					: null}
		</p>*/
	);
};

export default AutoSubmit;