import {useIndexedDB} from "react-indexed-db-hook";
import useIntervalState from "./useIntervalState";

export default function useVehiclesState() {
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll} = useIndexedDB("vehicles");
	const [vehicles, setVehicles] = useIntervalState((setState) => {
		getAll().then((vehiclesFromDB) => {
			setState(vehiclesFromDB);
		}).catch(e => setState([]));
	}, [])

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return vehicles.find(vehicle => vehicle[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((entity) => deleteRecord(entity.id));
		}
	}

	return {vehicles, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll};
}