import {fetchData} from "../../services/api";
import useTokenSessionState from "../useTokenSessionState";

const validateMerchantApple = async (json) => {
    const response = await fetchData("Payment/ApplePay/MerchantSession", "POST", "body", {
        "validationURL": json.validationURL
    });
    return response;
}

const generateUUID = () => {
    let
        d = new Date().getTime(),
        d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
};

const paymentGWBrowserData = {
        "acceptHeader": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
        "javaEnabled": false,
        "language": navigator.language,
        "colorDepth": window.screen.colorDepth,
        "screenHeight": window.innerHeight,
        "screenWidth": window.innerWidth,
        "timezone": new Date().getTimezoneOffset(),
        "userAgent": window.navigator.userAgent,
        "javascriptEnabled": true
};

export const tokenPaymentProcess = async (ticket, token, navigate = null) => {
    let json = await fetchData("Payment/CustomerTokenPayment/Process", "POST", "body", {
        "ticketGUID": ticket,
        "paymentGWToken": token,
        "paymentGWBrowserData": paymentGWBrowserData
    });

    if(navigate && json.paymentSuccessful === true) {
        navigate(`/paid/${json.ticket.ticketGUID}`);
    }

    if(json.actions?.authenticate.browserChallenge.url) window.location.href = json.actions.authenticate.browserChallenge.url;

    return json;
}

export const applePayPaymentProcess = async (ticket, navigate = null) => {
    let json = await fetchData("Payment/ApplePay/Process", "POST", "body", {
        "ticketGUID": ticket,
        "browser": paymentGWBrowserData
    });

    if(navigate && json.paymentSuccessful === true) {
        navigate(`/paid/${json.ticket.ticketGUID}`);
    }

    if(json.actions?.authenticate.browserChallenge.url) window.location.href = json.actions.authenticate.browserChallenge.url;

    return json;
}

export const orderTicket = async (licencePlate, email, requestedMinutes, paymentMethod, parkingZone = null, paymentToken = null, addTokenSession = null, price = null, navigate = null) => {

    console.log(paymentMethod);

    if(paymentMethod && parkingZone) {
        let session:any;
        if(paymentMethod === '3'){

                if (!PaymentRequest) {
                    return;
                }

                try {

                    const paymentMethodData = [{
                        "supportedMethods": "https://apple.com/apple-pay",
                        "data": {
                            "version": 3,
                            "merchantIdentifier": "merchant.eu.praha.parkingportal",
                            "merchantCapabilities": [
                                "supports3DS"
                            ],
                            "supportedNetworks": [
                                "amex",
                                "discover",
                                "masterCard",
                                "visa"
                            ],
                            "countryCode": "CZ"
                        }
                    }];

                    const paymentDetails = {
                        "total": {
                            "label": "Parkování",
                            "amount": {
                                "value": Number(price),
                                "currency": "CZK"
                            }
                        }
                    };

                    const paymentOptions = {
                        "requestPayerName": false,
                        "requestBillingAddress": false,
                        "requestPayerEmail": false,
                        "requestPayerPhone": false,
                        "requestShipping": false,
                    };

                    // @ts-ignore
                    const request = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions);
                    // @ts-ignore
                    request.onmerchantvalidation = async event => {
                        const merchantSessionPromise = await validateMerchantApple(event);
                        event.complete(merchantSessionPromise);
                    };

                    // @ts-ignore
                    request.onpaymentmethodchange = event => {
                        // @ts-ignore
                        if (event.methodDetails.type !== undefined) {
                            const paymentDetailsUpdate = {
                                'total': paymentDetails.total
                            };
                            // @ts-ignore
                            event.updateWith(paymentDetailsUpdate);
                        }
                    };

                    const response = await request.show();
                    const status = "success";
                    await response.complete(status);
                    session = btoa(JSON.stringify(response.details.token.paymentData));
                } catch (e) {

                }
        }

        let returnUrl, randomUUID;

        if(paymentMethod === '0') returnUrl = window.location.protocol + "//" + window.location.host + "/payment/process";
        else if(paymentMethod === '1'){
            randomUUID = generateUUID();
            returnUrl = window.location.protocol + "//" + window.location.host + "/payment/token/" + randomUUID;
        }
        else if(paymentMethod === '3'){
            randomUUID = generateUUID();
            returnUrl = window.location.protocol + "//" + window.location.host + "/payment/applepay/" + randomUUID;
        }

        let params = {
            "licensePlate": licencePlate,
            "email": email,
            "sectionCode": parkingZone?.sectionCode,
            "tariff": parkingZone?.tariffInfo?.tariffID,
            "requestedMinutes": Number(requestedMinutes),
            "ticketPayment": {
                "paymentMethod": paymentMethod,
                "createToken": true,
                "paymentGWToken": paymentToken,
                "paymentGWBrowserData": paymentGWBrowserData,
                "paymentGWReturnURL": returnUrl,
                "sdkUsed": false,
                "paymentData": session
            },
            "language": "cs"
        };

         fetchData("Tickets", "POST", "body", params).then((data) => {
            if(paymentMethod === '0') window.location.href = data.paymentGWRedirectURL;
            if(paymentMethod === '1'){
                addTokenSession({
                   "uuid": randomUUID,
                    "ticketGUID": data.ticket.ticketGUID,
                    "paymentGWReference": data.paymentGWReference
                });
                    let iframe = document.createElement("iframe");
                    iframe.setAttribute("src", data.actions.fingerPrint.browserInit.url);
                    iframe.style.width = "1px";
                    iframe.style.height = "1px";
                    iframe.style.display = "none";
                    document.body.appendChild(iframe);
                    let i = 0;
                document.querySelector("iframe").addEventListener( "load", function(e) {
                    if(i===1){
                        tokenPaymentProcess(data.ticket.ticketGUID, data.paymentGWReference, navigate);
                        setInterval(() => {
                            tokenPaymentProcess(data.ticket.ticketGUID, data.paymentGWReference, navigate)
                        }, 2000)
                    }
                    i++;
                } );

            }
            if(paymentMethod === '3'){
                addTokenSession({
                    "uuid": randomUUID,
                    "ticketGUID": data.ticket.ticketGUID,
                    "paymentGWReference": null
                });
                applePayPaymentProcess(data.ticket.ticketGUID, navigate);
                setInterval(() => {
                    applePayPaymentProcess(data.ticket.ticketGUID, navigate)
                }, 2000)
            }
        });

    }
}