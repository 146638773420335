//import React, {useEffect, useState} from "react";
import styles from './LanguageSwitcher.module.scss';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import csFlag from "../../assets/images/cs.svg";
import enFlag from "../../assets/images/en.svg";
import Cookies from "js-cookie";


const LanguageSwitcher: React.FC = () => {
    const {t } = useTranslation();

    const changeLang = (e: string) => {
        Cookies.set('lang', e);
        i18n.changeLanguage(e);
    };

    const lang = i18n.language;

    return (
        <a className={styles.languageSwitcher} onClick={() => changeLang(lang === "cs" ? "en" : "cs")}>
            <img height={14} src={lang === "cs" ? enFlag : csFlag} alt={lang === "cs" ? t("home.LanguageSwitcherComponent.en") : t("home.LanguageSwitcherComponent.cs")} />
        </a>
    );
};

export default LanguageSwitcher;