import React from "react";
import savedStyles from '../Settings.module.scss';
import {Link, useNavigate, useParams} from "react-router-dom";
import Icon from "../../components/Icon";
import RemoveDialog from "./RemoveDialog";
import useCardsState from "../../model/useCardsState";
import {useTranslation} from "react-i18next";

const RemoveCard = () => {
	const {deleteByIndex, getByIndex} = useCardsState();
	const {cardId} = useParams();
	const card = getByIndex('id', cardId);
	const navigateTo = useNavigate();

	const {t} = useTranslation();

	return (
		<div className={savedStyles._container}>
			<div className={savedStyles.wrapper}>
				<Link to={'/settings'} className={`d-flex-center gap-3 ${savedStyles.backLink}`}>
					<Icon.LeftChevron size={12} />
					{t("settings.removeCard.back")}
				</Link>
				<h1>{t("settings.removeCard.title")}</h1>
				{card && (
					<RemoveDialog
						type={'card'}
						title={`${card.name}`}
						onCancel={() => {
							navigateTo('/settings');
						}}
						onSubmit={() => {
							deleteByIndex('id', cardId)
								.then(() => {
									navigateTo('/settings');
								});
						}}
					/>
				)}

			</div>
		</div>
	);
}

export default RemoveCard;