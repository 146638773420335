import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import styles from './MapResultsSheet.module.scss';
import Sheet, {SheetRef} from "react-modal-sheet";
import MapResults from "../MapResults/MapResults";
import {TSection} from "../../model/type/api/TSection";

type Props = {
	filteredZones,
    hideResults: boolean,
	snapPoint,
	forceClose?: boolean,
	onSelect?: (section: TSection) => void
} & PropsWithChildren

const MapResultsSheet = ({ children, filteredZones, forceClose = false,  ...props }: Props) => {
	const [snapPoints, setSnapPoints] = useState([-145, 100]);
	const [currentSnapPoint, setCurrentSnapPoint] = useState(1);
	const [disableDrag, setDisableDrag] = useState(false);

	const ref2 = useRef(null);
	const ref3 = useRef(null);
	const ref = useRef<SheetRef>();

	useEffect(() => {
		if (forceClose) {
			setIsMapResultsOpen(false);
		} else {
			setIsMapResultsOpen(true);
		}
	}, [forceClose]);

	const [isMapResultsOpen, setIsMapResultsOpen] = useState(false);

	useEffect(() => {
		if (!forceClose) {
			setIsMapResultsOpen(true);
			snapTo(1)
		}
	}, [isMapResultsOpen]);


	useEffect(() => {
		setSnapPoints(props.snapPoint);
		if (currentSnapPoint !== 1) {
			snapTo(currentSnapPoint);
		}
	}, [props.snapPoint]);


	const snapTo = (i: number) => ref.current?.snapTo(i);

	return (
		<div className={`${styles.bottomPanel} ${styles.showOnPhone}`} ref={ref2}>
			<Sheet
				isOpen={isMapResultsOpen}
				onClose={() => {
					setIsMapResultsOpen(false);
				}}
				onCloseEnd={() => snapTo(1)}
				snapPoints={snapPoints}
				initialSnap={currentSnapPoint}
				className={styles.mapResultsContainer}
				mountPoint={ref2.current}
				onSnap={snapIndex => setCurrentSnapPoint(snapIndex)}
				tweenConfig={{ease: 'easeOut', duration: 0.2}}
				disableDrag={disableDrag}
			>
				<Sheet.Container className={styles.mapResults} ref={ref3}>
					<Sheet.Header className={styles.header} />
					<Sheet.Content>
						<MapResults
							snapIndex={currentSnapPoint}
							filteredResults={filteredZones}
                            hideResults={props.hideResults}
							onChangeScroll={(y) => {
								setDisableDrag(y > 0);
							}}
							onSelect={(section) => {
								props.onSelect && props.onSelect(section);
							}}
						/>
					</Sheet.Content>
				</Sheet.Container>
			</Sheet>
		</div>
	);
};

export default MapResultsSheet;
