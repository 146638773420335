import styles from './ParkingSessionItem.module.scss';
import Icon from "../Icon";
import ProgressBar from "../ProgressBar/ProgressBar";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import {t} from "i18next";
import React, { useState, useEffect } from 'react';
import useVehiclesState from "../../model/useVehiclesState";
import useParkingSession from "../../model/useParkingSession";

type Props = {
	from: string
	to: string,
	ticketGUID: string
	carPlate: string
};

const ParkingSessionItem: React.FCC<Props> = ({ ...props}) => {

	dayjs.extend(relativeTime)
	dayjs.extend(duration)

	const navigate = useNavigate();

	const { getByIndexAsync: getVehicleByIndexAsync} = useVehiclesState();

	const [carPlate, setCarPlate] = useState(null);

	useEffect(() => {
		getVehicleByIndexAsync("spz", props.carPlate).then((vehicle) => {
			if(vehicle) setCarPlate(vehicle.name);
		})
	}, []);

	return (
		<div className={styles.item}
			 onClick={() => navigate(`/paid/${props.ticketGUID}`)}>
			<div className={styles.info}>
				<div className={styles.icon}>
					<Icon.Car size={22}/>
				</div>
				<div className={styles.title}>
					<div className={styles.remaining}>
						{getTimeUntil(props.to, props.ticketGUID)}
					</div>
					<div className={styles.parkingInfo}>
						{carPlate ?? props.carPlate}
					</div>
				</div>
				<div className={styles.openIcon}>
					<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M2.12695 0.71875L8.20312 7.06055C8.33594 7.22656 8.43555 7.42578 8.43555 7.625C8.43555 7.82422 8.33594 8.02344 8.20312 8.15625L2.12695 14.498C1.82812 14.8301 1.29688 14.8301 0.998047 14.5312C0.666016 14.2324 0.666016 13.7344 0.964844 13.4023L6.54297 7.5918L0.964844 1.81445C0.666016 1.51562 0.666016 0.984375 0.998047 0.685547C1.29688 0.386719 1.82812 0.386719 2.12695 0.71875Z"
							fill="#6E8CB8"/>
					</svg>
				</div>
			</div>
			<ProgressBar onlyBar={true} orderDate={dayjs(props.from).toDate()} endDate={dayjs(props.to).toDate()}></ProgressBar>
		</div>
	);
}

export default ParkingSessionItem;


const getTimeUntil = (targetDateTime: string, ticketGUID) => {
	const now = dayjs();
	const targetTime = dayjs(targetDateTime);
	const {deleteByIndex} = useParkingSession();


	if (targetTime.isBefore(now)) {
		deleteByIndex("guid", ticketGUID);
		return t("progressBarComponent.end");
	}

	const duration = dayjs.duration(targetTime.diff(now));

	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	let txt = "";

	if(hours > 0) txt += `${hours} `;
	if(hours === 1) txt += t("global.timeFull.hour1") + " ";
	if(hours >= 2 && hours <= 4) txt += t("global.timeFull.hour2_4") + " ";
	if(hours > 4) txt += t("global.timeFull.hour5+") + " ";

	if(minutes > 0) txt += `${minutes} `;
	if(minutes === 1) txt += t("global.timeFull.minute1")
	if(minutes >= 2 && minutes <= 4) txt += t("global.timeFull.minute2_4")
	if(minutes > 4) txt += t("global.timeFull.minute5+")

	if (minutes === 0) {
		txt += `${seconds} `;
		if (seconds === 1) txt += t("global.timeFull.second1")
		if (seconds >= 2 && seconds <= 4) txt += t("global.timeFull.second2_4")
		if (seconds > 4 || seconds === 0) txt += t("global.timeFull.second5+")
	}

	return txt;
}