import * as mapSettings from "../assets/map.json";

type TPLaceItem = {
	id: string,
	name: string
};

type TContextItem = {
	place: TPLaceItem;
};

type TSuggestedResultsItem = {
	context: TContextItem;
	name: string,
	name_preferred: string,
	mapbox_id: string,
	feature_type: string,
	address: string,
	full_address: string,
	place_formatted: string,
	language: string,
	maki: string,
}

type TSuggestedResults = {
	attribution: string,
	suggestions: TSuggestedResultsItem[]
}

const {mapMaxExtent} = mapSettings;

export class Mapbox {
	accessToken: string;

	constructor(accessToken: string) {
		this.accessToken = accessToken;
	}

	public getSuggestedResults(query: string): Promise<TSuggestedResults> {
		return this.getData('/search/searchbox/v1/suggest', {
			q: query,
			session_token: '29195389-e06b-41c1-9a6d-f8c1db8e7f7f',
			country: 'CZ',
			//limit: 10,
			proximity: '14.421253,50.087465', // Praha
			bbox: mapMaxExtent.join(","),
			types: ["street"]
		}).then(res => res.json());
	}

	public getSuggestedFeature(suggestedResultsItem: TSuggestedResultsItem): any {
		return this.getData(`/search/searchbox/v1/retrieve/${suggestedResultsItem.mapbox_id}`, {
			session_token: '29195389-e06b-41c1-9a6d-f8c1db8e7f7f'
		}).then(res => res.json());
	}

	private getData(path: string, params: any) {
		const paramsUrl = new URLSearchParams({...params, access_token: this.accessToken}).toString();
		return fetch(`https://api.mapbox.com${path}?${paramsUrl}`, {
			method: 'GET'
		});
	}
}