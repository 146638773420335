import React from "react";
import savedStyles from '../Settings.module.scss';
import {Link, useNavigate, useParams} from "react-router-dom";
import Icon from "../../components/Icon";
import RemoveDialog from "./RemoveDialog";
import useVehiclesState from "../../model/useVehiclesState";
import {t} from "i18next";
import useEmailsState from "../../model/useEmailsState";

const RemoveVehicle = () => {
	const {deleteByIndex, getByIndex} = useEmailsState();
	const {emailId} = useParams();
	const email = getByIndex('email', emailId);
	const navigateTo = useNavigate();

	return (
		<div className={savedStyles._container}>
			<div className={savedStyles.wrapper}>
				<Link to={'/settings'} className={`d-flex-center gap-3 ${savedStyles.backLink}`}>
					<Icon.LeftChevron size={12} />
					{t("settings.removeEmail.back")}
				</Link>
				<h1>{t("settings.removeEmail.title")}</h1>
				{email && (
					<RemoveDialog
						type={'email'}
						title={email.email}
						onCancel={() => {
							navigateTo('/settings');
						}}
						onSubmit={() => {
							deleteByIndex('email', emailId);
							navigateTo('/settings');
						}}
					/>
				)}
			</div>
		</div>
	);
}

export default RemoveVehicle;