export const LANGUAGES = [
    { label: "Čeština", code: "cs" },
    { label: "English", code: "en" }
];

export const PARKING_TIME_VALUES = [
    { value: "15", label: "", labelString: "timeOptions.15minutes", isDisabled: false },
    { value: "30", label: "", labelString: "timeOptions.30minutes", isDisabled: false },
    { value: "45", label: "", labelString: "timeOptions.45minutes", isDisabled: false },
    { value: "60", label: "", labelString: "timeOptions.60minutes", isDisabled: false },
    { value: "90", label: "", labelString: "timeOptions.90minutes", isDisabled: false },
    { value: "120", label: "", labelString: "timeOptions.2hours", isDisabled: false },
    { value: "180", label: "", labelString: "timeOptions.3hours", isDisabled: false },
    { value: "240", label: "", labelString: "timeOptions.4hours", isDisabled: false },
    { value: "300", label: "", labelString: "timeOptions.5hours", isDisabled: false },
    { value: "360", label: "", labelString: "timeOptions.6hours", isDisabled: false },
    { value: "420", label: "", labelString: "timeOptions.7hours", isDisabled: false },
    { value: "480", label: "", labelString: "timeOptions.8hours", isDisabled: false },
    { value: "540", label: "", labelString: "timeOptions.9hours", isDisabled: false },
    { value: "600", label: "", labelString: "timeOptions.10hours", isDisabled: false },
    { value: "660", label: "", labelString: "timeOptions.11hours", isDisabled: false },
    { value: "720", label: "", labelString: "timeOptions.12hours", isDisabled: false },
    { value: "780", label: "", labelString: "timeOptions.13hours", isDisabled: false },
    { value: "840", label: "", labelString: "timeOptions.14hours", isDisabled: false },
    { value: "900", label: "", labelString: "timeOptions.15hours", isDisabled: false },
    { value: "960", label: "", labelString: "timeOptions.16hours", isDisabled: false },
    { value: "1020", label: "", labelString: "timeOptions.17hours", isDisabled: false },
    { value: "1080", label: "", labelString: "timeOptions.18hours", isDisabled: false },
    { value: "1140", label: "", labelString: "timeOptions.19hours", isDisabled: false },
    { value: "1200", label: "", labelString: "timeOptions.20hours", isDisabled: false },
    { value: "1260", label: "", labelString: "timeOptions.21hours", isDisabled: false },
    { value: "1320", label: "", labelString: "timeOptions.22hours", isDisabled: false },
    { value: "1380", label: "", labelString: "timeOptions.23hours", isDisabled: false },
    { value: "1440", label: "", labelString: "timeOptions.24hours", isDisabled: false }
];

export const PAID_NOTIFICATION_TIMES = [
    {value: '0',  label: "", labelString: 'paid.notification.optionWithout'},
    {value: '5',  label: "", labelString: 'paid.notification.option5minutes'},
    {value: '15',  label: "", labelString: 'paid.notification.option15minutes'},
    {value: '30',  label: "", labelString: 'paid.notification.option30minutes'}
]