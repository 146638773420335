import styles from './Page404.module.scss';

const Page404 = () => {
	return (
		<div className={styles.errorNotFountWrapper}>
			<div className={styles.errorNotFount}>
				<h1>404</h1>
				<h2>Stránka neexistuje</h2>
			</div>
		</div>
	);
}

export default Page404;