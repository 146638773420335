import {useIndexedDB} from "react-indexed-db-hook";
import useIntervalState from "./useIntervalState";

export default function useSettingsState() {
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll} = useIndexedDB("settings");
	const [settings, setSettings] = useIntervalState((setState) => {
		getAll().then((settingsFromDB) => {
			setState(settingsFromDB);
		}).catch(e => setState([]));
	}, [])

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return settings.find(settings => settings[indexName] === key);
	}

	const getSettingsValue = (value: any) => {
		return settings.find(settings => settings['key'] === value);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((entity) => deleteRecord(entity.id));
		}
	}

	return {settings, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll, getSettingsValue};
}