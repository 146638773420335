import React from 'react';
import { components } from 'react-select';

const SelectOption = (props) => {
    return (
        <components.Option {...props}>
            <span style={{
                borderBottom: props.isSelected ? '2px solid #C82128' : '1px dashed #888B8D',
                fontWeight: props.isSelected || props.isFocused ? 'bold' : 'normal',
                paddingBottom: '12px',
                display: 'block'
            }}>
                {props.data.label}
            </span>
        </components.Option>
    );
};

export default SelectOption;
