import React, {useEffect, useState} from 'react';
import styles from './Layout.module.scss';
import logo from '../assets/images/logo.svg';
import menuIcon from '../assets/images/menu.svg';
import accountIcon from '../assets/images/acount.svg';
import {Link, Outlet} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Icon from "../components/Icon";
import LanguageSwitcher from "../components/LanguageSwitcher/LanguageSwitcher";
import 'assets/scss/global.scss';
import {useTranslation} from "react-i18next";
import useSettingsState from "../model/useSettingsState";
import Cookies from "js-cookie";
import useParkingSession from "../model/useParkingSession";
import useBuyFormValues from "../model/useBuyFormValues";
import useTokenSessionState from "../model/useTokenSessionState";

type Props = {

};

const Layout: React.FCC<Props> = ({...props}) => {
	const [mobileNavbarOpened, setMobileNavbarOpened] = useState(false);
	const navigate = useNavigate();

	const getSidePanelClass = () => {
		if (window.location.pathname === '/' || window.location.pathname.startsWith("/q/")) {
			return `${styles.sidePanel} ${styles.sidePanelMap}`;
		}

		return styles.sidePanel;
	};

	const {t} = useTranslation();

	const { getSettingsValue, add: addSettingsValue, settings, deleteRecord: deleteSettingsValue} = useSettingsState();
	const { clearAll: clearAllParkingSession } = useParkingSession();
	const { clearAll: clearAllBuyFormValues } = useBuyFormValues();
	const { clearAll: clearTokenSessionState } = useTokenSessionState();

	const [deployment, setDeployment] = useState(null);

	useEffect(() => {
		fetch('/deploy.txt')
			.then(response => response.text())
			.then(text => setDeployment(text.trim()))
	}, []);

	const [isAppVersionSaved, setIsAppVersionSaved] = useState(false);
	const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);

	useEffect(() => {
		if (settings && Object.keys(settings).length > 0) {
			setIsSettingsLoaded(true);
		}
	}, [settings]);

	useEffect(() => {
		const checkAndSaveAppVersion = async (isLoaded) => {
			try {
				const deployVersion = process.env.REACT_APP_VERSION + "-" + deployment;
				const appVersion = await getSettingsValue('appVersion');

				if (!appVersion && isLoaded) {
					await addSettingsValue({
						key: 'appVersion',
						value: deployVersion
					});
					setIsAppVersionSaved(true);
				} else if (appVersion?.value !== deployVersion && isLoaded) {
					await deleteSettingsValue('appVersion');
					await addSettingsValue({
						key: 'appVersion',
						value: deployVersion
					});
					setIsAppVersionSaved(true);

					Cookies.remove("lang");
					await clearAllParkingSession();
					await clearAllBuyFormValues();
					await clearTokenSessionState();
				}
			} catch (error) {
				console.error("Error saving app version:", error);
			}
		};

		if (deployment && isSettingsLoaded && !isAppVersionSaved) {
			checkAndSaveAppVersion(isSettingsLoaded);
		} else if (deployment) {
			checkAndSaveAppVersion(isSettingsLoaded);
		}
	}, [deployment, isSettingsLoaded, isAppVersionSaved]);

	return (
		<div className={styles.layout}>
			<div className={styles.navbar}>
				<img className={styles.logo} src={logo} onClick={() => navigate('/')}/>
				<div className={styles.menu}>
					<Link to={'/'} type="button" className={'btn'}>{t("menu.home")}</Link>
					<Link to={'/settings'} type="button" className={'btn'}>{t("menu.settings")}</Link>
					<LanguageSwitcher />
				</div>
			</div>
			<div className={getSidePanelClass()}>
				<Outlet/>
			</div>
		</div>
	);
}

export default Layout;