import React, {useEffect, useState} from "react";
import styles from './MapMyLocation.module.scss';
import { transform } from 'ol/proj';
import {map} from "../Map";
import View from "ol/View";
import * as mapSettings from "../../assets/map.json";
import {useTranslation} from "react-i18next";
const { mapCenter, mapZoom , mapAnimationDuration} = mapSettings;
type Props = {
    showOn: "mobile" | "desktop";
};

const MapMyLocation: React.FC<Props> = ({showOn}) => {
    const [view, setView] = useState<View | null>(null);
    const {t } = useTranslation();

    useEffect(() => {
        setView(map.getView());
    }, []);

    const handleMyLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { coords } = position;
                    const { latitude, longitude } = coords;

                    const [mappedLongitude, mappedLatitude] = transform(
                        [longitude, latitude],
                        'EPSG:4326',
                        'EPSG:3857'
                    );

                    view.animate({center: [mappedLongitude, mappedLatitude], zoom: mapZoom});
                },
                (error) => {
                    const [mappedLongitude, mappedLatitude] = transform(
                        mapCenter,
                        'EPSG:4326',
                        'EPSG:3857'
                    );

                    view.animate({center: [mappedLongitude, mappedLatitude], zoom: mapZoom, duration: mapAnimationDuration});

                    console.error("Error getting user's location:", error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser");
        }
    };


    return (
        <a className={`${styles.myPositionButton} ${showOn === "mobile" ? styles.displayOnMobile : ""}  ${showOn === "desktop" ? styles.displayOnDesktop : ""}`} onClick={handleMyLocation}>
            <svg width="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className={styles.changeColor} d="M8.90625 12.9062C8.71875 13.3438 8.25 13.5938 7.78125 13.5C7.3125 13.4062 6.96875 13 6.96875 12.5V7H1.46875C1 7 0.59375 6.6875 0.5 6.21875C0.40625 5.75 0.65625 5.28125 1.09375 5.09375L12.0938 0.59375C12.4688 0.4375 12.9062 0.53125 13.1875 0.8125C13.4688 1.09375 13.5625 1.53125 13.4062 1.90625L8.90625 12.9062Z" fill="#1D4F8B"/>
                <path d="M8.90625 12.9062C8.71875 13.3438 8.25 13.5938 7.78125 13.5C7.3125 13.4062 6.96875 13 6.96875 12.5V7H1.46875C1 7 0.59375 6.6875 0.5 6.21875C0.40625 5.75 0.65625 5.28125 1.09375 5.09375L12.0938 0.59375C12.4688 0.4375 12.9062 0.53125 13.1875 0.8125C13.4688 1.09375 13.5625 1.53125 13.4062 1.90625L8.90625 12.9062Z" fill="black" fillOpacity="0.2"/>
            </svg>
            <span className={styles.myPositionButtonText}>&nbsp;{t("home.MapMyLocationComponent.title")}</span>
        </a>
    );
};

export default MapMyLocation;