import * as mapSettings from "../assets/map.json";
import mapboxgl from "mapbox-gl";
import {centerOfMass, distance, point, centroid, bbox} from "@turf/turf";

const {mapCenter, mapZoom, mapMaxExtent, minZoom, maxZoom} = mapSettings;

const getBottomPointFromCenterOfMass = (polygon: mapboxgl.MapboxGeoJSONFeature, centerOfMassFeature: any): [number, number] | null => {
    // Získáme všechny souřadnice, buď pro Polygon nebo MultiPolygon
    let coordinates: number[][][];

    if (polygon.geometry.type === 'Polygon') {
        coordinates = [polygon.geometry.coordinates[0]]; // Vložení jedné sady souřadnic pro Polygon
    } else if (polygon.geometry.type === 'MultiPolygon') {
        coordinates = polygon.geometry.coordinates.map(poly => poly[0]); // Vložíme všechny části z MultiPolygonu
    } else {
        console.error('Feature is not a Polygon or MultiPolygon');
        return null;
    }

    let bottomPoint: [number, number] | null = null;
    let minDistance = Infinity;

    const centerCoords = centerOfMassFeature; // Těžiště (střední bod polygonu)

    // Projdeme všechny body polygonu a najdeme bod nejblíže k těžišti ve spodní části polygonu
    for (let i = 0; i < coordinates.length; i++) {
        const currentPolygon = coordinates[i];
        for (let j = 0; j < currentPolygon.length; j++) {
            const currentPoint = currentPolygon[j];
            const currentDistance = distance(point(currentPoint), point(centerCoords));

            // Najdeme bod, který je na dolní hranici (nejnižší Y) a zároveň nejblíže těžišti
            if (currentPoint[1] < centerCoords[1] && currentDistance < minDistance) {
                minDistance = currentDistance;
                bottomPoint = [currentPoint[0], currentPoint[1]]; // Zajistíme, že je to [longitude, latitude]
            }
        }
    }

    return bottomPoint;
};

export const loadParkingZonesIntoMap = (data: any, map: mapboxgl.Map): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        try {
            // GeoJSON objekt pro nové zóny
            const geoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
                type: "FeatureCollection",
                features: data.map((zone) => ({
                    type: "Feature",
                    geometry: JSON.parse(zone.sectionBorder),
                    properties: {
                        sectionID: zone.sectionID,
                        sectionCategoryColor: zone.sectionCategoryColor
                    }
                }))
            };

            const sourceId = "parkingZonesSource";

            // Přidání nebo aktualizace zdroje dat
            let source = map.getSource(sourceId) as mapboxgl.GeoJSONSource;
            if (!source) {
                map.addSource(sourceId, {
                    type: 'geojson',
                    data: geoJson,
                    promoteId: 'sectionID'
                });
            } else {
                source.setData(geoJson);
            }

            const fillLayerId = "parkingZonesFillLayer";
            const lineLayerId = "parkingZonesLineLayer";

            // Přidání výplňové vrstvy pro zóny
            if (!map.getLayer(fillLayerId)) {
                map.addLayer({
                    id: fillLayerId,
                    type: 'fill',
                    source: sourceId,
                    paint: {
                        'fill-color': ['get', 'sectionCategoryColor'],
                        'fill-opacity': [
                            'case',
                            ['boolean', ['feature-state', 'selected'], false],
                            0.6,  // Vyšší opacity při výběru
                            ['boolean', ['feature-state', 'hover'], false],
                            0.3,  // Zvýšená opacity při hover
                            0.1   // Výchozí opacity
                        ]
                    }
                });
            }

            // Přidání obrysové vrstvy pro zóny
            if (!map.getLayer(lineLayerId)) {
                map.addLayer({
                    id: lineLayerId,
                    type: 'line',
                    source: sourceId,
                    paint: {
                        'line-color': ['get', 'sectionCategoryColor'],
                        'line-opacity': 1,
                        'line-dasharray': [6, 4]
                    }
                });
            }

            // Správa stavů hover a selection přímo v této funkci
            let hoveredFeatureId: string | null = null;
            let selectedFeatureId: string | null = null;

            // Událost při najetí myší na konkrétní feature (polygon)
            map.on('mousemove', fillLayerId, (e) => {
                if (e.features && e.features.length > 0) {
                    map.getCanvas().style.cursor = 'pointer'; // Změna kurzoru
                    const feature = e.features[0];

                    if (!feature.id) {
                        console.error("Feature does not have an ID:", feature);
                        return;
                    }

                    if (hoveredFeatureId && hoveredFeatureId !== feature.id) {
                        // Resetujeme hover stav předchozího prvku
                        map.setFeatureState(
                            { source: sourceId, id: hoveredFeatureId },
                            { hover: false }
                        );
                    }

                    // Nastavíme hover stav pro aktuální prvek
                    hoveredFeatureId = feature.id as string;
                    map.setFeatureState(
                        { source: sourceId, id: feature.id },
                        { hover: true }
                    );
                }
            });

            // Událost při opuštění polygonu myší
            map.on('mouseleave', fillLayerId, () => {
                map.getCanvas().style.cursor = ''; // Reset kurzoru
                if (hoveredFeatureId) {
                    // Resetujeme hover stav
                    map.setFeatureState(
                        { source: sourceId, id: hoveredFeatureId },
                        { hover: false }
                    );
                    hoveredFeatureId = null;
                }
            });

// Proměnná pro uložení aktuálního popupu
            let currentPopup: mapboxgl.Popup | null = null;

// Přidání popupu při kliknutí na feature
            map.on('click', fillLayerId, (e) => {
                if (e.features && e.features.length > 0) {
                    const feature = e.features[0];

                    if (!feature.id) {
                        console.error("Feature does not have an ID:", feature);
                        return;
                    }

                    // Pokud již existuje otevřený popup, uzavřeme ho
                    if (currentPopup) {
                        currentPopup.remove();
                    }

                    // Použití Turf.js pro výpočet bounding boxu polygonu
                    const bounds = bbox(feature); // bbox vrací [minLng, minLat, maxLng, maxLat]

                    // Výpočet těžiště polygonu
                    const centerOfMassVal = centerOfMass(feature).geometry.coordinates;

                    // Ruční výpočet zoomu na základě velikosti bounding boxu
                    const lngDiff = bounds[2] - bounds[0];
                    const latDiff = bounds[3] - bounds[1];

                    // Definujeme minimální a maximální hodnoty zoomu
                    const minZoom = 10; // Minimalní přiblížení
                    const maxZoom = 15; // Maximální přiblížení

                    // Vypočítáme vhodný zoom na základě velikosti bounding boxu
                    let calculatedZoom = Math.max(minZoom, Math.min(maxZoom, 20 - Math.log(lngDiff + latDiff) * 2));

                    // Použijeme easeTo místo fitBounds
                    map.easeTo({
                        center: centerOfMassVal as [number, number], // Nastavíme těžiště jako střed mapy
                        zoom: calculatedZoom, // Použijeme vypočítaný zoom
                        duration: 500, // Plynulý přechod
                        offset: [0, -window.innerHeight / 4]
                    });

                    const bottomPoint = getBottomPointFromCenterOfMass(feature, centerOfMassVal);

                    const htmlContent = `
            <div>
                <h3>Section ID: ${feature.properties.sectionID}</h3>
                <p>Color: ${feature.properties.sectionCategoryColor}</p>
            </div>
        `;

                    // Vytvoření popupu a nastavení jeho pozice
                    currentPopup = new mapboxgl.Popup({ anchor: 'top' }) // Uchováme referenci na popup
                        .setLngLat(bottomPoint as [number, number]) // Nastavení pozice
                        .setHTML(htmlContent) // Nastavení HTML obsahu
                        .addTo(map); // Přidání popupu do mapy

                    // Pokud je nějaký prvek vybrán, resetujeme jeho stav
                    if (selectedFeatureId && selectedFeatureId !== feature.id) {
                        map.setFeatureState(
                            { source: sourceId, id: selectedFeatureId },
                            { selected: false }
                        );
                    }

                    // Nastavíme nový stav selected pro aktuální prvek
                    selectedFeatureId = feature.id as string;
                    map.setFeatureState(
                        { source: sourceId, id: feature.id },
                        { selected: true }
                    );
                }
            });



            // Událost při kliknutí mimo jakoukoli feature
            map.on('click', (e) => {
                const features = map.queryRenderedFeatures(e.point, {
                    layers: [fillLayerId]
                });

                // Uzavření popupu, pokud klikneme mimo zóny
                if (!features.length && currentPopup) {
                    currentPopup.remove();
                    currentPopup = null;
                }

                if (!features.length && selectedFeatureId) {
                    // Pokud není žádná feature a nějaký prvek byl vybrán, resetujeme jeho stav
                    map.setFeatureState(
                        { source: sourceId, id: selectedFeatureId },
                        { selected: false }
                    );
                    selectedFeatureId = null;
                }
            });

            resolve();
        } catch (error) {
            console.error("Error loading zones:", error);
            reject(error);
        }
    });
};

export const checkMapWidthLimit = (map: mapboxgl.Map): boolean => {
    const bounds = map.getBounds();
    const widthInDegrees = bounds.getEast() - bounds.getWest();
    const limit = 0.05;
    return widthInDegrees <= limit;
}


export const mapDebounce = <F extends (...args: any[]) => void>(func: F, wait: number): ((...args: Parameters<F>) => void) => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    return (...args: Parameters<F>) => {
        const later = () => {
            timeoutId = null;
            func(...args);
        };

        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(later, wait);
    };
};