import React, {PropsWithChildren, useEffect, useState} from "react";
import styles from './Checkbox.module.scss';
import {Field, useField} from "formik";

type Props = {
	value?: boolean,
	onChangeValue?: (newValue: boolean) => void
	wrapperClassName?:  string,
	name: string,
	field?: any
} & PropsWithChildren & any;

const Checkbox: React.FCC<Props> = ({onChangeValue, children, ...props}) => {
	props.type = 'text';
	const [field, meta, helpers] = useField(props);

	return (
		<label className={`${styles.wrapper} ${props.wrapperClassName}`}>
			<input {...field} {...props} />
			<svg className={styles.checkbox} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<rect x="0.5" y="0.5" width="23" height="23" fill="#F3F3F5"/>
				<rect x="0.5" y="0.5" width="23" height="23" stroke="#6E8CB8"/>
				{field.value && (
					<>
						<path d="M6.75 12L10.5 15.75L18 8.25" stroke="#1D4F8B" strokeWidth="2"/>
						<path d="M6.75 12L10.5 15.75L18 8.25" stroke="black" strokeOpacity="0.2" strokeWidth="2"/>
					</>
				)}
			</svg>
			{children}
		</label>
	);
}

export default Checkbox;
