import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import reportWebVitals from './reportWebVitals';
import App from "./App";

import "./i18n";
import {IndexedDB} from "react-indexed-db-hook";
import {DBConfig} from "./model/DBConfig";
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<IndexedDB {...DBConfig}>
		<App />
	</IndexedDB>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
