import {useIndexedDB} from "react-indexed-db-hook";
import useIntervalState from "./useIntervalState";

export default function useCardsState() {
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll, getByID} = useIndexedDB("cards");
	const [cards, setCards] = useIntervalState((setState) => {
		getAll().then((cardsFromDB) => {
			setState(cardsFromDB);
		}).catch(e => setState([]));
	}, [])

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return cards.find(card => card[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((card) => deleteRecord(card.id));
		}
	}

	return {cards, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll};
}