import {FieldProps, useField} from 'formik';
import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import StateManagedSelect from 'react-select';

type Props = any;

const colourStyles = {
    container: (styles, { isFocused }) => ({
        ...styles,
        width: "fit-content",
        minWidth: "100%",
        backgroundColor: isFocused ? "#fff" : '#F3F3F5',
        border: isFocused ? "1px solid #132E5C" : "1px solid rgba(0,0,0,0)",
        borderRadius: "4px",
        boxShadow: 'none',
    }),
    menu: (styles) => ({
        ...styles,
        width: "100%",
        backgroundColor: '#F3F3F5',
        boxShadow: 'none',
        outline: 0,
        marginTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        border: "1px solid #132E5C",
        padding: 0,
        margin: 0,
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
    }),
    control: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? "#fff" : '#F3F3F5',
        border: 'none',
        boxShadow: 'none',
    }),
    placeholder: styles => ({ ...styles, color: '#6E8CB8' }),
    option: (styles, { isDisabled, isFocused }) => ({
        ...styles,
        marginTop: 0,
        backgroundColor: isDisabled ? "#DADADC" : isFocused ? "#F3F3F5" : "#fff",
        color: '#000000',
        borderBottom: "1px solid #CCCFD7",
        cursor: isDisabled ? 'not-allowed' : 'default',
    }),
};

const SelectField: React.FC<Props> = ({...props}) => {
    const [field, meta, helpers] = useField(props);

    const [selectedOption, setSelectedOption] = useState(props.value ?? props.options[0] ?? null);

    useEffect(() => {
        if (props.value !== selectedOption) {
            setSelectedOption(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (selectedOption !== null) {
            helpers.setValue(selectedOption);
            if (props.onChange) {
                props.onChange(selectedOption);
            }
        }
    }, [selectedOption]);

    return (
        <Select
            {...props}
            options={props.options}
            value={selectedOption}
            styles={props?.styles || colourStyles}
            onChange={(newValue) => {
                setSelectedOption(newValue);
            }}
        />
    );
}

export default SelectField as StateManagedSelect;
