import {useIndexedDB} from "react-indexed-db-hook";
import useIntervalState from "./useIntervalState";

export default function useTokenSessionState() {
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll, getByID} = useIndexedDB("tokenPaymentSession");
	const [tokenPaymentSession, setTokenPaymentSession] = useIntervalState((setState) => {
		getAll().then((tokenPaymentSessionFromDB) => {
			setState(tokenPaymentSessionFromDB);
		}).catch(e => setState([]));
	}, [])

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return tokenPaymentSession.find(tokenPaymentSession => tokenPaymentSession[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((tokenPaymentSession) => deleteRecord(tokenPaymentSession.id));
		}
	}

	return {tokenPaymentSession, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll};
}