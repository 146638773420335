import mapboxLogo from "../../assets/images/mapbox.svg";
import styles from "./MapboxLogo.module.scss";
import React from "react";
import {Link} from "react-router-dom";

const MapboxLogo = (props) => {
    return (
        <Link to={"https://www.mapbox.com/"} target={"_blank"} rel={"noopener, nofollow"} className={styles.mapboxLink}>
            <img alt={"Mapbox Logo"} src={mapboxLogo} className={styles.mapboxLogo} height={"23px"}/>
        </Link>
    );
};

export default MapboxLogo;
