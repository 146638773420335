import styles from './MapSearch.module.scss';
import settingIcon from '../../assets/images/setting.svg';
import settingHoverIcon from '../../assets/images/setting.hover.svg';
import {useEffect, useRef, useState} from "react";
import Checkbox from "../Checkbox";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import AutoSubmit from "../form/AutoSubmit";
import * as api from "../../services/api";
import {TSectionCategory} from "../../model/type/api/TSectionCategory";
import {TFilter} from "../../model/type/MapSearch/TFilter";
import {FormikProps} from "formik/dist/types";
import * as React from "react";
import Icon from "../Icon";
import {useParams} from "react-router-dom";
import Select, {colourStyles} from "../form/Select/Select";
import {numbersRange} from "../../model/helper";
import moment from "moment";
import AsyncSelect from "react-select/async";
import {Mapbox} from "../Mapbox";
import {fetchData} from "../../services/api";
import {components} from "react-select";
import SelectOption from "../SelectOption/SelectOption";

type Props = {
	filter: any,
	wrapperClassName?: string,
	onOpenFilter?: () => void,
	onCloseFilter?: () => void,
	onChangeFilter: (values: TFilter) => void,
	isZoneVisibleOnMap: (sectionCode: string) => boolean
};

export const FilterInitialValues: TFilter = {
	query: {
		value: "",
		label: ""
	},
	parking: {
		to: null,
		length: null,
	},
	zones: {
		all: true,
		1: true,
		2: true,
		3: true
	}
}

const Input = (props) => <components.Input {...props} isHidden={false} />;

const MapSearch: React.FCC<Props> = ({filter, ...props})  => {
	const mapboxClient = new Mapbox(process.env.REACT_APP_MAP_API_KEY);

	const { sectionCode = null } = useParams();
	const [filterOpened, setFilterOpened] = useState(false);
	const formRef: React.Ref<FormikProps<any>> = useRef();
	const {t } = useTranslation();
	const [currentValues, setCurrentValues] = useState(filter);
	const [zones, setZones] = useState<TSectionCategory[]>([]);

	const [searchResults, setSearchResults] = useState(5);
	const selectInputRef = useRef();

	useEffect(() => {
		setCurrentValues(filter);
	}, [filter]);


	useEffect(() => {
		if (sectionCode) {
			formRef.current.setFieldValue('query', {label: sectionCode, value: "api_" + sectionCode});
			formRef.current.submitForm();
		}
	}, []);

	useEffect(() => {
		if (currentValues.query) {
			/*searchParams.set('zone', formRef.current?.values.query);
			setSearchParams(searchParams)*/
		}
	}, [currentValues.query]);

	useEffect(() => {
		let allTrue = true;
		Object.entries(currentValues.zones).forEach(([key, value]) => {
			if(value !== true && key !== "all") allTrue = false;
		});
		formRef.current.setFieldValue('zones[all]', allTrue);
	}, [currentValues.zones]);

	const loadZones = () => {
		api.fetchData('Parking/sectionCategories')
			.then((zones) => {
				setZones(zones);

                const zoneValues = zones.reduce((acc, zone) => ({
                    ...acc,
                    [zone.sectionCategoryID]: true
                }), {});

                // formRef.current.setValues({
                //     ...formRef.current.values,
                //     zones: zoneValues
                // });
			})
	}

	useEffect(() => {
		loadZones();
	}, []);

	const onSubmit = (values, { setSubmitting }) => {
		props.onChangeFilter && props.onChangeFilter(values);
		setSubmitting(false);
	}

	useEffect(() => {
		filterOpened && props.onOpenFilter?.();
		!filterOpened && props.onCloseFilter?.();
	}, [filterOpened]);

	const getDurationOptions = () => {
		let durations = [];

		[15,30,45,60,90].map((minutes) => {
			durations.push({
				value: minutes,
				label: `${minutes} min.`
			});
		});

		numbersRange(2, 24).map((hours) => {
			durations.push({
				value: hours * 60,
				label: `${hours} hod.`
			});
		});

		return durations;
	}

	const getToOptions = () => {
		const now = moment();
		const tomorrow = now.clone().add(1, 'day')
		return getDurationOptions()
				.map((option) => {
					const newTime = now.clone().add(option.value, 'minutes');
					return {
						label: newTime.format('HH:mm') + (newTime.isSame(tomorrow, 'day') ? ' - ' + t("home.MapSearchComponent.tomorrow") : ''),
						value: option.value,
					};
				});
	}

    const handleToChange = (option) => {
        formRef.current.setFieldValue('parking.length', option.value);
    }

    const handleLengthChange = (option) => {
        formRef.current.setFieldValue('parking.to', option.value);
    }

	const durationOptions = getDurationOptions();
	const toOptions = getToOptions();

	const [searchValue, setSearchValue] = useState("");

	const [selectedValue, setSelectedValue] = useState(null);

	const filterHasSectionCode = (inputValue) => inputValue.match(/P\d+.*/gmi) || inputValue.match(/BUS.*/gmi);

	const fetchOptions = async (inputValue) => {
		if (filterHasSectionCode(inputValue)) {
			const response = await fetchData("Parking/sections/2", "GET", "url",
				{
					sectionCode: inputValue.toUpperCase()
				});
			if (response.length > 0) {
				setSearchResults(response.length);
				return response.map((item) => ({
					value: "api_" + item.sectionCode,
					label: item.streetName ?? item.sectionCode
				}));
			}
		} else {
			const response = await mapboxClient.getSuggestedResults(inputValue);
			setSearchResults(response.suggestions.length);
			return response.suggestions.map((item) => ({
				value: "mapbox_" + item.mapbox_id,
				label: (item.name_preferred ?? item.name) + ", " + item.context.place.name
			}));
		}
	};

	const loadOptions = (inputValue, callback) => {
		setSearchValue(inputValue);
		fetchOptions(inputValue).then(callback);
	};

	const [value, setValue] = useState();
	const [inputValue, setInputValue] = useState("");

	const onInputChange = (inputValue, { action }) => {
		if (action === "input-change") {
			setInputValue(inputValue);
		}
	};

	// @ts-ignore
	const onFocus = () => value && selectInputRef.current.select.inputRef.select();

	return (
		<Formik initialValues={FilterInitialValues} onSubmit={onSubmit} innerRef={formRef} >
			{({setValues, values, setFieldValue}) => {
				setCurrentValues(values);
				return (
                    <Form>
					<div className={`${styles.mapSearchContainer} ${props.wrapperClassName}`}>
						<div className={styles.searchInputTitle}>{t("home.MapSearchComponent.title")}</div>
						<div className={styles.searchInputWrapper}>
							<a className={`${styles.filterButton} ${filterOpened ? styles.opened : ''}`} onClick={() => setFilterOpened(!filterOpened)}>
								<img src={filterOpened ? settingHoverIcon : settingIcon} />
							</a>
							<div className={styles.searchInputGroup}>
								<AsyncSelect
									value={value}
									inputValue={inputValue}
									onInputChange={onInputChange}
									onFocus={onFocus}
									loadOptions={loadOptions}
									controlShouldRenderValue={false}
									name={'query'}
									styles={colourStyles}
									ref={selectInputRef}
									components={{
										Input,
										DropdownIndicator: (props) => (
											<div style={{ paddingRight: "10px", display: selectedValue?.label?.length ?? 0 > 1 ? "none" : "block" }}>
												<Icon.Search size={15} />
											</div>
										),
										IndicatorSeparator: () => null,
										ClearIndicator: (props) => (
											<div
												style={{ paddingRight: "10px", zIndex: 10000, cursor: 'pointer' }}
												onClick={() => { setSelectedValue(""); setInputValue(""); setValue(null); }}
												onTouchStart={() => { setSelectedValue(""); setInputValue(""); setValue(null); }}
											>
												<Icon.Remove size={15} />
											</div>
										),
										Option: SelectOption
									}}
									onChange={(selectedOption) => {
										setSelectedValue(selectedOption);
										setValue(selectedOption);
										setInputValue(selectedOption ? selectedOption.label : "");
										if (selectedOption === null) {
											setFieldValue("query", { "label": "", "value": "" });
										} else {
											setFieldValue("query", selectedOption);
										}
									}}
									noOptionsMessage={() => t("home.MapSearchComponent.searchNoResults")}
									loadingMessage={() => t("home.MapSearchComponent.searchLoading")}
									placeholder={t("home.MapSearchComponent.searchPlaceholder")}
									isClearable={true}
								/>


							</div>
						</div>
						<AutoSubmit debounceMs={1} />
						{filterOpened && (
							<div className={styles.filtration}>
								<div className={styles.filterHeader}>
									<div className={styles.filterTitle}>{t("home.MapSearchComponent.mapFilter")}</div>
									<div
										className={styles.clearFilters}
										onClick={() => {
                                            setValues(FilterInitialValues);
                                            setCurrentValues(FilterInitialValues);
											// @ts-ignore
											selectInputRef.current.clearValue();
										}}
									>
										{t("home.MapSearchComponent.removeFilter")}
									</div>
								</div>
								<div className={styles.filterDuration}>
									<div className={styles.filterTitle}>{t("home.MapSearchComponent.filterTitle")}</div>
									<div className={styles.inputGroups}>
										<div className={styles.inputGroup}>
											<Select name={'parking.to'}
												placeholder={t("home.MapSearchComponent.to")}
												options={toOptions}
												isSearchable={false}
                                                onChange={handleToChange}
											/>
										</div>
										<div className={styles.inputGroup}>
											<Select name={'parking.length'}
												placeholder={t("home.MapSearchComponent.length")}
												options={durationOptions}
												isSearchable={false}
                                                onChange={handleLengthChange}
											/>
										</div>
									</div>
								</div>
								<div className={styles.filterZones}>
									<div className={styles.filterTitle}>{t("home.MapSearchComponent.zones.title")}</div>
									<div className={styles.filterOptions}>
										<div className={styles.checkboxGroup}>
											<Checkbox
												name={'zones[all]'}
												wrapperClassName={styles.checkbox}
												onClick={(e) => {
													let boolValue = (e.target.value === "true");
													Object.entries(currentValues.zones).forEach(([key, value]) => {
														if(key !== "all") setFieldValue(`zones[${key}]`, !boolValue);
													});
												}}
											>
												<b>{t("home.MapSearchComponent.zones.showAll")}</b>
											</Checkbox>
										</div>
										{zones.map((zone) => (
											<div key={zone.sectionCategoryID} className={styles.checkboxGroup}>
												<Checkbox
													name={`zones[${zone.sectionCategoryID.toString()}]`}
													wrapperClassName={styles.checkbox}
													onClick={(e) => {
														let boolValue = (e.target.value === "true");
														setFieldValue(`zones[${zone.sectionCategoryID.toString()}]`, !boolValue)
													}}
												>
													<span>
														{t("home.MapSearchComponent.zones." + zone.sectionCategoryName)}
														{/* TODO: poresit na backendu a tohodle se zbavit v dalsi fazi ^^ */}
														{/*{zone.sectionCategoryName}*/}
														<svg className={styles.zoneColorDot} xmlns="http://www.w3.org/2000/svg"
															 width="9" height="10" viewBox="0 0 9 10" fill="none">
															<circle cx="4.5" cy="5" r="4.5" fill={zone.sectionCategoryColor}/>
														</svg>
													</span>
												</Checkbox>
											</div>
										))}
									</div>
								</div>
							</div>
						)}
				</div>
                </Form>
			)}}
		</Formik>
	);
}

export default MapSearch;
