import React, {useEffect, useState} from 'react';
import styles from './ProgressBar.module.scss';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {calculateParkingTimes} from "../../model/helpers/DateHelper";

type Props = {
	orderDate: Date,
	endDate: Date,
    onlyBar?: boolean
}

const ProgressBar = ({ orderDate, endDate, ...props }: Props) => {
    const {t } = useTranslation();
    const navigate = useNavigate();
    let parkingTimes = calculateParkingTimes(orderDate, endDate);

    const [timeLeft, setTimeLeft] = useState('');
    const [progress, setProgress] = useState(100);
    const [remainingTime, setRemainingTime] = useState(parkingTimes.remaining || 0);

    useEffect(() => {
        const updateProgress = () => {
            const times = calculateParkingTimes(orderDate, endDate);
            const remaining = times.remaining;

            const progressPercentage = (remaining / times.total) * 100;
            setProgress(progressPercentage);

            let remainingFormatted = '';
            if (remaining > 3600000) { // More than 1 hour
                const hours = Math.floor(remaining / 3600000);
                const minutes = Math.floor((remaining % 3600000) / 60000);
                remainingFormatted = t("progressBarComponent.timeLeftHours", {"hours": hours, "minutes": minutes});
            } else if (remaining <= 0) {
                remainingFormatted = t("progressBarComponent.end");
            } else {
                const minutes = Math.floor(remaining / 60000);
                const seconds = Math.floor((remaining % 60000) / 1000);
                remainingFormatted = t("progressBarComponent.timeLeftMinutes", {"minutes": minutes, "seconds": seconds});
            }

            setRemainingTime(remaining);
            setTimeLeft(remainingFormatted);

            if (remaining <= 0) {
                if (intervalId) {
                    clearInterval(intervalId);
                }
                setProgress(0);
                setRemainingTime(0);
            }
        };

        let intervalId = null;

        updateProgress();

        intervalId = setInterval(updateProgress, (parkingTimes.remaining > 3600000 ? 20000 : 1000));

        return () => clearInterval(intervalId);
    }, [orderDate, endDate, navigate]);

	return (
        !props.onlyBar ?
            <div className={`${styles.progressInfo}`}>
                <div className={styles.progressTitle}>
                    <div
                        className={styles.textMuted}>{remainingTime > 0 ? dayjs(orderDate).format('HH:mm') : dayjs(orderDate).format('HH:mm D. M. YYYY')}</div>
                    <strong className={`${remainingTime <= 0 && 'text-danger'}`}>{timeLeft}</strong>
                    <div>{remainingTime > 0 ? dayjs(endDate).format('HH:mm') : dayjs(endDate).format('HH:mm D. M. YYYY')}</div>
                </div>
                <div className={styles.progressBar}>
                    <div className={`${styles.progress} ${remainingTime <= 0 && styles.progressDanger}`}
                         style={remainingTime > 0 ? {'width': progress + '%'} : null}></div>
                </div>
            </div>
            :
            <div className={`${styles.progressBar} ${styles.progressBarParkingSession}`}>
                <div className={`${styles.progress} ${remainingTime <= 0 && styles.progressDanger}`}
                     style={remainingTime > 0 ? {'width': progress + '%'} : null}></div>
            </div>
    );
};

export default ProgressBar;
