import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat)

export const formatTarrifPricing = (tarrif) => {

    let formatted = [];

    const days = ["holiday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    tarrif.activeTariffGroups.forEach((tarrifEl) => {
        let firstDay:string;
        let lastDay:string;

        // First and last day of the array
        days.forEach((el) => {
            if(tarrifEl[el] === true) {
                if (!firstDay) firstDay = el;
                lastDay = el;
            }
        });

        let detail = [];
        tarrifEl.activeTariffDefinitions.forEach((el) => {
            detail.push({"string": dayjs(el.timeFrom, "HH:mm:ss").format("HH:mm") + "-" + dayjs(el.timeTo, "HH:mm:ss").format("HH:mm"), "price": el.pricePerHour})
        });

        formatted.push({
            "firstDay": firstDay,
            "lastDay": lastDay,
            "detail": detail,
        });
        //console.log(firstDay + "-" + lastDay);
    });

    if(tarrif.maxPriceHoliday){
        formatted.push({
            "firstDay": "holiday",
            "detail": [{"string": "", "price": tarrif.maxPriceHoliday}],
        });
    }

    return formatted;
}