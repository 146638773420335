import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from './Paid.module.scss';
import Icon from "../components/Icon";
import {Link, useNavigate, useParams} from "react-router-dom";
import FormSelect from "components/FormSelect";
import {PAID_NOTIFICATION_TIMES} from "../constants";
import i18n from "i18next";
import dayjs from "dayjs";
import {calculateParkingTimes} from "../model/helpers/DateHelper";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import {downloadReceipt, getTicket} from "../model/helpers/Session";
import {fetchData} from "../services/api";

const Paid = () => {
    const {t } = useTranslation();
    const navigate = useNavigate();
    const notificationSelectOptions = PAID_NOTIFICATION_TIMES;

    const [notificationMinutes, setNotificationMinutes] = useState(notificationSelectOptions[0]);
    const [shouldRender, setShouldRender] = useState(false);
    const [ticketData, setTicketData] = useState(null);
    const [sectionName, setSectionName] = useState(null);

    let { ticketGUID } = useParams();

    useEffect(() => {
        getTicket(ticketGUID, (data) => {
            data.parkingFrom = dayjs(data.parkingFrom).toDate()
            data.parkingTo = dayjs(data.parkingTo).toDate()

            setTicketData(data);

            const { remaining } = calculateParkingTimes(data.parkingFrom, data.parkingTo);

            fetchData("Parking/sections/1", "GET", "url", {
                "sectionCode": data.sectionCode
            }).then((sectionsData) => {
                if (sectionsData[0]) {
                    setSectionName(sectionsData[0]?.sectionCategoryName)
                }
            });

            if (remaining <= 0) {
                navigate(`/parking-session/${ticketGUID}`);
            } else {
                setShouldRender(true);
            }
        })
    }, [ticketGUID, navigate]);

    useEffect(() => {
        notificationSelectOptions.forEach((el, i) => {
            el.label = t(PAID_NOTIFICATION_TIMES[i].labelString);
        })
    }, [i18n.language]);

    const handleDownloadReceipt = () => {
        downloadReceipt(ticketGUID);
    };

    if (!shouldRender) {
        return null;
    }

    return (
        <div className={styles.containerWrapper}>
            <div className={styles.container}>
                <Link to={'/'} className={styles.back}>
                    <Icon.CaretLeft color={'danger'} size={16}/>
                    &nbsp; {t("paid.back")}
                </Link>

                <h1 className={styles.header}>{t("paid.title")}</h1>

                <div className={styles.item}>
                    <div className={`${styles.info}`}>
                        <ProgressBar orderDate={ticketData?.parkingFrom} endDate={ticketData?.parkingTo} />
                    </div>
                </div>

                {/*<div className={styles.item}>
                    <div className={styles.info}>
                        <div className={styles.icon}>
                            <Icon.Alarm size={21}/>
                        </div>
                        <div className={styles.title}>
                            <div className={styles.itemTitle}>
                                {t("paid.notification.title")}
                            </div>
                        </div>
                        <div>
                            <FormSelect
                                name={"notificationMinutes"}
                                defaultValue={notificationMinutes}
                                onChange={setNotificationMinutes}
                                options={notificationSelectOptions}
                                isSearchable={false}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>*/}

                <div className="row">
                    <div className="col-md-6">
                        <div className={styles.item}>
                            <div className={styles.info}>
                                <div className={styles.icon}>
                                    <Icon.ParkingSign size={21}/>
                                </div>
                                <div className={styles.textMuted}>
                                    {sectionName}
                                </div>
                                <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                    <div className={styles.itemTitle}>
                                        {ticketData?.sectionCode}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={styles.item}>
                            <div className={styles.info}>
                                <div className={styles.icon}>
                                    <Icon.Car size={21}/>
                                </div>
                                <div className={styles.textMuted}>
                                    {t('paid.car')}
                                </div>
                                <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                    <div className={styles.itemTitle}>
                                        {ticketData?.licensePlate}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className={styles.item}>
                            <div className={styles.info}>
                                <div className={styles.icon}>
                                    <Icon.PaymentCard size={21}/>
                                </div>
                                <div className={styles.textMuted}>
                                    {t('paid.paid.title')}
                                </div>
                                <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                    <div className={styles.itemTitle}>
                                        {t('paid.paid.byCard')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={styles.item}>
                            <div className={styles.info}>
                                <div className={styles.icon}>
                                    <Icon.Pricing size={21}/>
                                </div>
                                <div className={styles.textMuted}>
                                    {t('paid.price')}
                                </div>
                                <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                    <div className={styles.itemTitle}>
                                        {ticketData?.priceTotal}&nbsp;{t('global.currency.czk')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* TODO: rozchodit ve 3. fázi */}
                {/*<div className={styles.item}>*/}
                {/*    <div className={styles.info}>*/}
                {/*        <div className={`${styles.icon} ${styles.leavingIcon}`}>*/}
                {/*            <Icon.CarAlt color={'danger'} size={16}/>*/}
                {/*        </div>*/}

                {/*        <div className={styles.leaving}>*/}
                {/*            <div className={`${styles.leavingTitleWrapper} d-flex d-md-none`}>*/}
                {/*                <span className={`${styles.leavingTitle}`}>{t('paid.leavingMobile')}</span>*/}
                {/*                <Link to={'/'} className={`${styles.back} mb-0`}>*/}
                {/*                    {t("paid.cancelParking")}*/}
                {/*                    <Icon.CaretRight color={'danger'} size={12}/>*/}
                {/*                </Link>*/}
                {/*            </div>*/}
                {/*            <span className={`${styles.leavingTitle} d-none d-md-inline`}>{t('paid.leaving')}</span>*/}
                {/*            <span className={''}>{t('paid.leavingInfo')}</span>*/}
                {/*            &nbsp;<strong>25&nbsp;{t('global.currency.czk')}!</strong>*/}
                {/*        </div>*/}

                {/*        <Link to={'/'} className={`${styles.leavingBtn} btn btn-danger d-none d-md-inline-block`}>*/}
                {/*            {t("paid.cancelParking")}*/}
                {/*        </Link>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={styles.formActions}>
                    <button type="submit"
                            className={`${styles.actionBtn} ${styles.defaultBtn} ${styles.text500}`}
                            onClick={() => handleDownloadReceipt()}
                    >
                        <Icon.Document color={'black'} size={16}/>&nbsp;{t('paid.invoice')}
                    </button>

                    {/*<Link to={`/extend-parking/${ticketGUID}`} className={`${styles.actionBtn} ${styles.mainBtn}`}>
                        <Icon.ClockAlt color={'active'} size={16}/>&nbsp;{t('paid.extend')}
                    </Link>*/}
                </div>
            </div>
        </div>
    );
}

export default Paid;