import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const languageDetector = new LanguageDetector();

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .use(languageDetector)
    .init({
        detection: {
            order: ["cookie", "navigator"],
            lookupCookie: 'lang',
            convertDetectedLanguage: (lng) => lng.split('-')[0]
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${window.location.origin}/lang/{{lng}}.json`,
        },
    });

export default i18n;