import React, {useEffect, useMemo, useRef, useState} from "react";
import {fetchData} from "../../services/api";
import {Link, useNavigate, useParams} from "react-router-dom";
import styles from "./ProcessPaymentByCard.module.scss";
import Icon from "../../components/Icon";
import useVehiclesState from "../../model/useVehiclesState";
import useCardsState from "../../model/useCardsState";
import {t} from "i18next";
import useTokenSessionState from "../../model/useTokenSessionState";
import {applePayPaymentProcess, tokenPaymentProcess} from "../../model/helpers/Payment";
import FullscreenLoader from "../../components/FullscreenLoader/FullscreenLoader";
import useParkingSession from "../../model/useParkingSession";
import useBuyFormValues from "../../model/useBuyFormValues";

const ProcessPaymentByCard = (props) => {
    let paramsString = window.location.search;
    const searchParams = useMemo(() => new URLSearchParams(paramsString), [paramsString]);
    const navigate = useNavigate();

    const [caption, setCaption] = useState("paymentDone.caption.licencePlateAndCard");

    const fetchInitiated = useRef(false);
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [vehicleExist, setVehicleExist] = useState(false);
    const [cardExist, setCardExist] = useState(false);

    const [vehicleName, setVehicleName] = useState(null);
    const [cardName, setCardName] = useState(null);

    const { getByIndexAsync: getVehicleByIndexAsync, add: addVehicle } = useVehiclesState();
    const { getByIndexAsync: getCardByIndexAsync, add: addCard } = useCardsState();
    const { getByIndexAsync: getTokenSessionState } = useTokenSessionState();
    const { getLatestByDateTime: getBuyFormValuesLatest } = useBuyFormValues();
    const { add: addParkingSession } = useParkingSession();
    const {token} = useParams();

    let interval = null;

    useEffect( () => {

        // Payment canceled by user
        if (searchParams.get("paymentStatus") === "3") {
            getBuyFormValuesLatest().then((latest) => {
                navigate(`/buy/${latest.sectionCode}?getLatestValues=true`)
            });
        }

        if (props.applepay === true) {
            getTokenSessionState("uuid", token).then(session => {
                interval = setInterval(() => {
                    try {
                        applePayPaymentProcess(session.ticketGUID).then(r => {
                            if (r.paymentSuccessful === true) {
                                clearInterval(interval);
                                setPaymentInfo(r);
                                addParkingSession({
                                    "guid": r.ticket.ticketGUID,
                                    "from": r.ticket.parkingFrom,
                                    "to": r.ticket.parkingTo,
                                    "ticket": r.ticket
                                });
                                setCardExist(true);
                            }
                        });
                    }catch (e) {
                        clearInterval(interval);
                        navigate(`/`);
                    }
                }, 2000);
            });
        }

        if (props.token === true) {
            getTokenSessionState("uuid", token).then(session => {
                console.log(session);
                interval = setInterval(() => {
                    try {
                    tokenPaymentProcess(session.ticketGUID, session.paymentGWReference).then(r => {
                        if (r.paymentSuccessful === true) {
                            clearInterval(interval);
                            setPaymentInfo(r);
                            addParkingSession({
                                "guid": r.ticket.ticketGUID,
                                "from": r.ticket.parkingFrom,
                                "to": r.ticket.parkingTo,
                                "ticket": r.ticket
                            });
                        }
                    });
                    }catch (e) {
                        clearInterval(interval);
                        navigate(`/`);
                    }
                }, 2000);
            });
        } else {
            if (
                searchParams.has("payId") &&
                searchParams.has("dttm") &&
                searchParams.has("resultCode") &&
                searchParams.has("resultMessage") &&
                searchParams.has("paymentStatus") &&
                searchParams.has("authCode") &&
                searchParams.has("merchantData") &&
                !paymentInfo &&
                !fetchInitiated.current
            ) {
                fetchInitiated.current = true;
                fetchData("Payment/GatewayResult/Process", "POST", "body", searchParams.toString(), false).then((results) => {
                    if (results) {
                        setPaymentInfo(results);
                        addParkingSession({
                            "guid": results.ticket.ticketGUID,
                            "from": results.ticket.parkingFrom,
                            "to": results.ticket.parkingTo,
                            "ticket": results.ticket
                        });
                    } else {
                        if (fetchInitiated.current === false) {
                            navigate(`/`);
                        }
                    }
                });
            } else {
                if (fetchInitiated.current === true) navigate(`/`);
                /* Testovací parametry:
                setPaymentInfo({
                    "success": true,
                    "message": null,
                    "paymentGWReference": "tkn_e234454f89e84aecbbb01d002bdc1d06",
                    "paymentSuccessful": true,
                    "paymentGatewayOperationCancelled": false,
                    "paymentGatewayOperationDenied": false,
                    "isTokenPaidOrder": false,
                    "ticket": {
                        "ticketGUID": "e234454f89e84aecbbb01d002bdc1d06",
                        "dateCreated": "2024-02-29T10:38:51.417",
                        "priceTotal": 20,
                        "vat": 0,
                        "vatRate": 0,
                        "licensePlate": "fff",
                        "paymentStatus": "PAID",
                        "paymentDate": "2024-02-29T10:39:19.173",
                        "sectionCode": "P1-0276",
                        "parkingFrom": "2024-02-29T10:38:51.413",
                        "acceptedMinutes": 15,
                        "parkingTo": "2024-02-29T10:53:51.413"
                    }
                });*/
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (paymentInfo){
            if(props.token === true) setCardExist(true);

            getVehicleByIndexAsync("spz", paymentInfo.ticket.licensePlate)
               .then(vehicle => {
                   if(vehicle){
                       setVehicleExist(true);
                       setCaption("paymentDone.caption.card");
                   }
                   if(!props.token === true) {
                       getCardByIndexAsync("cardMaskedCLN", paymentInfo.ticket.cardMaskedCLN)
                           .then(vehicle => {
                               if (vehicle) {
                                   setCardExist(true);
                                   setCaption("paymentDone.caption.licencePlate");
                               }

                               if (vehicleExist && cardExist) navigate(`/paid/${paymentInfo.ticket.ticketGUID}`);
                           })
                   }
               })


        }
    }, [paymentInfo]);

    useEffect(() => {
        if (vehicleExist && cardExist) navigate(`/paid/${paymentInfo.ticket.ticketGUID}`);
    }, [vehicleExist, cardExist])

    const saveOnDevice = () => {
        if(!vehicleExist && vehicleName){
            addVehicle({
                "spz": paymentInfo.ticket.licensePlate,
                "name": vehicleName
            })
        }

        if(!cardExist && cardName){
            addCard({
                name: cardName,
                token: paymentInfo.paymentGWReference,
                cardMaskedCLN: paymentInfo.ticket.cardMaskedCLN,
                cardExpiration: paymentInfo.ticket.cardExpiration,
            })
        }

        navigate(`/paid/${paymentInfo.ticket.ticketGUID}`);
    }

    return (
        paymentInfo === null ?
        <div className={styles.Wrapper}>
            <FullscreenLoader />
        </div>
            :
        <div className={styles.containerWrapper}>
            <div className={styles.container}>
                <Link to={'/'} className={styles.back}>
                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.5 10.75C5.28906 10.75 5.10156 10.6797 4.96094 10.5391L0.460938 6.03906C0.15625 5.75781 0.15625 5.26562 0.460938 4.98438L4.96094 0.484375C5.24219 0.179688 5.73438 0.179688 6.01562 0.484375C6.32031 0.765625 6.32031 1.25781 6.01562 1.53906L2.05469 5.5L6.01562 9.48438C6.32031 9.76562 6.32031 10.2578 6.01562 10.5391C5.875 10.6797 5.6875 10.75 5.5 10.75Z"
                            fill="#C32B3E"/>
                    </svg>
                    &nbsp;{t("paymentDone.leave")}
                </Link>
                <h3 className={styles.title}>{t("paymentDone.paymentSuccessful")}</h3>
                <p className={styles.caption}>{t(caption)}</p>
                {!vehicleExist && paymentInfo?.ticket?.licensePlate ?
                <div className={styles.item}>
                    <div className={styles.info}>
                        <div className={styles.icon}>
                            <Icon.Car size={22}/>
                        </div>
                        <div className={styles.title}>
                            <div className={styles.itemTitle}>
                                {t("paymentDone.car")}
                            </div>
                        </div>
                        <div className={styles.textMuted}>
                            {paymentInfo.ticket.licensePlate}
                        </div>
                        <div className={`${styles.itemEnd} ${styles.itemEndExtended} ${styles.inputMobileMax}`}>
                            <input onChange={(evt) => setVehicleName(evt.target.value)} placeholder={t("paymentDone.nameCar")}
                                   className={styles.input}/>
                        </div>
                    </div>
                </div> : "" }
                {!cardExist && !props.token === true && paymentInfo?.ticket?.cardMaskedCLN ?
                <div className={styles.item}>
                    <div className={styles.info}>
                        <div className={styles.icon}>
                            <Icon.PaymentCard size={22}/>
                        </div>
                        <div className={styles.title}>
                            <div className={styles.itemTitle}>
                                {t("paymentDone.card")}
                            </div>
                        </div>
                        <div className={styles.textMuted}>
                            •••• {paymentInfo.ticket.cardMaskedCLN.slice(-4)}
                        </div>
                        <div className={`${styles.itemEnd} ${styles.itemEndExtended} ${styles.inputMobileMax}`}>
                            <input onChange={(evt) => setCardName(evt.target.value)} placeholder={t("paymentDone.nameCard")}
                                   className={styles.input}/>
                        </div>
                    </div>
                </div> : "" }

                <div className={styles.buttons}>
                    <button className={styles.saveButton} onClick={() => saveOnDevice()}>
                        <Icon.SafeBadge className={styles.actionIcon} size={16}/>&nbsp;{t("paymentDone.saveOnThisDevice")}
                    </button>
                    <Link to={`/paid/${paymentInfo.ticket.ticketGUID}`} className={`${styles.saveButton} ${styles.skipButton}`}>
                        {t("paymentDone.skip")}&nbsp;<Icon.CaretRight className={styles.skipIcon} size={16}/>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default ProcessPaymentByCard;