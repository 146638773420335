import React from "react";
import styles from "./MapAttribution.module.scss";


const MapAttribution = (props) => {
    return (
        <span className={styles.attribution}>

            © <a target="_blank" href="https://www.mapbox.com/about/maps/">Mapbox</a>,
            © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>,&nbsp;
            <a href="https://labs.mapbox.com/contribute/" target="_blank">Improve this map</a>
        </span>
    );
};

export default MapAttribution;
